import styled from 'styled-components';

import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

import useResponsive from '../../hooks/useResponsive';

import assetSource from '../../data/assetSource';

import { SendInfoStep } from '../../types/ClaimInfo';

import PCSubmitButton from '../common/PCSubmitButton';
import Button from '../common/Button';

const Container = styled.div<{ isFirstStep: boolean; isBankInfoOptionStep: boolean; }>`
  display: flex;
  padding-left: 1em;
  justify-content: ${({ isFirstStep, isBankInfoOptionStep }) => ((isFirstStep || isBankInfoOptionStep) ? 'flex-end' : 'space-between')};
  align-items: flex-end;
  gap: 0.25em;

  ${media.isMobile`
    position: fixed;
    bottom: 0;
    left: 0;

    width: 100vw;
    gap: 0.5em;
    padding: 0.5em;

    border-top: 1px solid ${palette.containerLightGrey};
    background-color: ${palette.containerWhite};
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.30);
  `}
`;

const BackButton = styled.button`
  ${typography.body3}
  color: ${palette.primary};
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const MobileLeftButton = styled(Button)<{ isBankInfoOptionStep : boolean; }>`
  min-width: 120px;
  flex: ${({ isBankInfoOptionStep }) => (isBankInfoOptionStep ? 1 : 0)};
  padding-block: 1em;
  color: ${palette.primary};
  background-color: ${palette.tint9};
  ${typography.heading2Mobile}
`;

const RightButton = styled(PCSubmitButton)`
  min-width: 6.25em;
  justify-content: center;

  ${media.isMobile`
    flex: 1;
    padding-block: 1em;
    ${typography.heading2Mobile}
  `}
`;

function LeftButton({ isFirstStep, isBankInfoOptionStep, onClick }: {
  isFirstStep: boolean;
  isBankInfoOptionStep: boolean;
  onClick: () => void;
}) {
  const { isMobile } = useResponsive();

  if (isFirstStep) {
    return null;
  }

  if (isMobile) {
    return (
      <MobileLeftButton onClick={onClick} isBankInfoOptionStep={isBankInfoOptionStep}>
        {isBankInfoOptionStep ? '계좌번호 입력하기' : '돌아가기'}
      </MobileLeftButton>
    );
  }

  if (isBankInfoOptionStep) {
    return (
      <RightButton onClick={onClick} inactive={false}>
        계좌번호 입력하기
      </RightButton>
    );
  }

  return (
    <BackButton onClick={onClick}>
      {'< '}
      뒤로가기
    </BackButton>
  );
}

export default function SendInfoStepButtons({
  handleClickLeft, handleClickRight, inactive, sendInfoStep,
}:{
  handleClickLeft: () => void;
  handleClickRight: () => void;
  inactive: boolean;
  sendInfoStep: SendInfoStep;
}) {
  const { isMobile } = useResponsive();
  const isFirstStep = sendInfoStep === 'receiverName';
  const isBankInfoOptionStep = sendInfoStep === 'bankInfoOption';
  const isBankInfoStep = ['bankInfoOption', 'senderBankInfo'].includes(sendInfoStep);

  const rightButtonContent = () => {
    if (inactive) {
      return (
        <>
          <img src={`${assetSource}/assets/pen_white.svg`} alt="보내기" />
          <span>내용을 입력해주세요</span>
        </>
      );
    }

    if (isBankInfoStep) {
      return (
        <>
          <img src={`${assetSource}/assets/share-icon-v2.svg`} alt="보내기" />
          <span>내용증명 보내기</span>
        </>
      );
    }

    if (!isMobile) {
      return (
        <span>
          확인
        </span>
      );
    }

    return (
      <>
        <img src={`${assetSource}/assets/pen_white.svg`} alt="보내기" />
        <span>계속하기</span>
      </>
    );
  };

  return (
    <Container isFirstStep={isFirstStep} isBankInfoOptionStep={isBankInfoOptionStep}>
      <LeftButton
        isFirstStep={isFirstStep}
        isBankInfoOptionStep={isBankInfoOptionStep}
        onClick={handleClickLeft}
      />
      <RightButton
        className={isBankInfoStep ? 'complete-send-claim-button' : ''}
        onClick={handleClickRight}
        inactive={inactive}
      >
        {rightButtonContent()}
      </RightButton>
    </Container>
  );
}
