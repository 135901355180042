import { useRecoilState, useResetRecoilState } from 'recoil';
import {
  concatMap, interval, takeWhile,
} from 'rxjs';
import { useCallback } from 'react';

import claimInfoState from '../stores/claimInfoState';

import fetchClaimInfo from '../service/fetchClaimInfo';

import { ClaimStatus } from '../types/ClaimInfo';

const useClaimInfo = () => {
  const [claimInfo, setClaimInfo] = useRecoilState(claimInfoState);
  const initialize = useResetRecoilState(claimInfoState);

  const loadClaimInfo = useCallback((id: string) => {
    initialize();

    const subscription = interval(1000)
      .pipe(
        concatMap(() => fetchClaimInfo(id)),
        takeWhile((data) => (
          data.status === 'init' || data.status === 'modifying'
        ), true),
      )
      .subscribe((data) => {
        setClaimInfo(() => ({
          id,
          ...data,
        }));
      });

    return subscription;
  }, [initialize, setClaimInfo]);

  const updateClaimStatus = (status: ClaimStatus) => {
    setClaimInfo((prev) => ({
      ...prev,
      status,
    }));
  };

  return {
    claimInfo,
    loadClaimInfo,
    updateClaimStatus,
  };
};

export default useClaimInfo;
