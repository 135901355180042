import bokeAPI from './bokeAPI';

export default async function completeModifyClaim({
  claimId,
}: {
  claimId: string;
}) {
  const { data } = await bokeAPI.post(`/claims/${claimId}/modify/complete`);

  return data;
}
