import styled from 'styled-components';
import { useRef, useState } from 'react';
import { useOnClickOutside } from 'usehooks-ts';

import assetSource from '../../data/assetSource';
import sajuDropDownOptions from '../../data/sajuDropDownOptions';

import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';
import useBirthInput from '../../hooks/saju/useBirthInput';
import useResponsive from '../../hooks/useResponsive';

import { SajuBirthKey } from '../../types/SajuBirth';

const Container = styled.div<{ isOpen: boolean, disabled: boolean, hasValue: boolean }>`
  position: relative;
  display: flex;
  gap: 6px;
  padding-block: 6px;
  width: 170px;
  justify-content: center;
  align-items: center;

  border-radius: 25px;
  outline: ${({ hasValue }) => (hasValue ? `1px solid ${palette.containerLightGrey}` : 'none')};
  background-color: ${({ hasValue, disabled }) => {
    if (hasValue) {
      return 'none';
    }

    if (disabled) {
      return `${palette.containerLightGrey}`;
    }

    return `${palette.containerSuperLightGrey}`;
  }};

${media.isMobile`
    width: auto;
    flex: 1;
    padding-block: 8px;
  `}
  
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  }

  div {
    color: ${({ hasValue }) => (hasValue ? `${palette.textBlack}` : `${palette.textDarkGrey}`)};
    ${typography.body1}
  }

  img {
    display: ${({ disabled }) => (disabled ? 'none' : 'block')};
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'none')};
    transition: 0.4s ease;
  }
`;

const Options = styled.div`
  width: 100%;
  position: absolute;
  bottom: 120%; 
  left: 0;
  max-height: 170px;
  overflow-y: scroll;
  border-radius: 12px;
  background: ${palette.containerSuperLightGrey};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  
  &::-webkit-scrollbar {
    display: none;
  }

  div {
    min-height: 34px;
    color: ${palette.textBlack};
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    ${typography.body1}

    &:hover{
      cursor: pointer;
      ${typography.body1Bold}
    }
  }
`;

const getPlaceHolder = (isMobile: boolean, key: SajuBirthKey) => {
  const placeHolderDictionary: {
    [Key in SajuBirthKey] : string
  } = {
    year: isMobile ? '년도' : '태어난 년도',
    month: isMobile ? '월' : '태어난 월',
    day: isMobile ? '날짜' : '태어난 날',
    hour: '태어난 시간',
    gender: '성별',
  };
  return placeHolderDictionary[key];
};

export default function SajuDropDown({
  selectKey,
}: {
  selectKey: SajuBirthKey;
}) {
  const {
    sajuBirth,
    updateSajuBirth,
  } = useBirthInput();
  const { isMobile } = useResponsive();
  const disabled = sajuBirth.birthTimeUnknown && selectKey === 'hour';
  const hasValue = Boolean(sajuBirth[selectKey]);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleSelect = (option: string) => {
    updateSajuBirth(selectKey, option);
    setIsOpen(false);
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const handleClickOutside = () => {
    setIsOpen(false);
  };
  useOnClickOutside(containerRef, handleClickOutside);

  return (
    <Container
      ref={containerRef}
      onClick={() => !disabled && setIsOpen(!isOpen)}
      isOpen={isOpen}
      disabled={disabled}
      hasValue={hasValue}
    >
      <div>{sajuBirth[selectKey] || getPlaceHolder(isMobile, selectKey)}</div>
      <img
        src={hasValue
          ? `${assetSource}/assets/saju-dropdown-downside-chevron-black.svg`
          : `${assetSource}/assets/saju-dropdown-downside-chevron-darkgrey.svg`}
        alt="드롭다운 화살표"
      />
      {isOpen && !disabled && (
        <Options>
          {sajuDropDownOptions[selectKey].map((option) => (
            <div role="none" onClick={() => handleSelect(option)}>
              {option}
            </div>
          ))}
        </Options>
      )}
    </Container>
  );
}
