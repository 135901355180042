import styled from 'styled-components';

import { useEffect, useState } from 'react';

import {
  concat, interval, map, takeWhile,
  generate,
  concatMap,
} from 'rxjs';

import { range } from 'ramda';
import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';
import { ProgressType } from '../../types/ProgressType';

import useConsultation from '../../hooks/useConsultation';
import LoadingIcon from '../common/LoadingIcon';

const AdviceContainer = styled.section`  
  display: flex;
  flex-direction: column;
  gap: 0.25em;

  p {
    min-height: 1.7em;
    color: ${palette.textBlack};
    ${typography.body1}
    display: flex;
    align-items: center;
    gap: 8px;

    ${media.isMobile`
      ${typography.body1}
    `}
  }
`;

const AnswerContainer = styled(AdviceContainer)`
  min-height: 6.25em;

  ${media.isMobile`
    min-height: 5em;
  `}
`;

const crescendo = (sentence: string) => range(0, sentence.length);
const fermata = (sentence: string) => Array(30).fill('').map(() => sentence.length);
const decrescendo = (sentence: string) => [...crescendo(sentence)].reverse();

const sequencePlot = (sentence: string) => [
  ...crescendo(sentence),
  ...fermata(sentence),
  ...decrescendo(sentence),
];

const playProgressObservable = (sentence: string) => interval(50).pipe(
  takeWhile((i) => i < sequencePlot(sentence).length),
  map((i) => sentence.slice(0, sequencePlot(sentence)[i])),
);

export default function ProgressSection({ type }: { type: ProgressType; }) {
  const { isAdvising } = useConsultation();

  const [progress, setProgress] = useState('');

  useEffect(() => {
    const sentences = (type === 'claim')
      ? [
        '엘리먼츠 AI로 상담내용을 분석하고 있습니다.',
        '내용증명을 작성하고 있습니다.',
      ]
      : consultationCategoryDictionary[type].loadingSentences;

    const iterateManyObservable = (count: number) => generate({
      initialState: 0,
      condition: (x) => x < count,
      iterate: (x) => x + 1,
    });

    const playObservable = () => concat(
      ...sentences.map(
        playProgressObservable,
      ),
    );

    const subscription = iterateManyObservable(10000)
      .pipe(concatMap(playObservable))
      .subscribe((p) => { setProgress(p); });

    return () => {
      subscription.unsubscribe();
    };
  }, [type]);

  if (isAdvising) {
    return (
      <AdviceContainer>
        <p>
          <LoadingIcon />
          {progress}
        </p>
      </AdviceContainer>
    );
  }

  return (
    <AnswerContainer>
      <p>
        <LoadingIcon />
        {progress}
      </p>
    </AnswerContainer>
  );
}
