import { useCallback, useState } from 'react';
import checkHasDraftClaim from '../service/checkHasDraftClaim';

export default function useHasDraftClaim() {
  const [hasDraftClaim, setHasDraftClaim] = useState<boolean>(false);
  const [hasDraftClaimLoading, setHasDraftClaimLoading] = useState<boolean>(false);
  const [draftClaimId, setDraftClaimId] = useState<string>('');

  const loadHasDraftClaim = useCallback(async (consultationId: string) => {
    setHasDraftClaimLoading(true);

    const response = await checkHasDraftClaim(consultationId);
    setHasDraftClaim(response.hasDraftClaim);
    if (response.hasDraftClaim) {
      setDraftClaimId(response.draftClaimId);
    }

    setHasDraftClaimLoading(false);
  }, []);

  return {
    loadHasDraftClaim,
    hasDraftClaim,
    draftClaimId,
    hasDraftClaimLoading,
  };
}
