import ConsultationCategory from '../types/ConsultationCategory';
import bokeAPI from './bokeAPI';

export default async function fetchAnswer(
  { consultationCategory, consultationId }:
  { consultationCategory: ConsultationCategory; consultationId: string; },
) {
  const { data } = await bokeAPI.get<{ isAnswering: boolean, answer: string }>(`/${consultationCategory}-consultations/${consultationId}/answers`);
  return data;
}
