import styled from 'styled-components';

import assetSource from '../../data/assetSource';

import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 1em;

  h3 {
    ${typography.heading3}
    color: ${palette.textBlack};
  }

  ${media.isMobile`
    gap: 0.5em;

    h3 {
      ${typography.heading2Mobile}
    }
  `}
`;

const LogoImage = styled.img`
  width: 35px;
  height: auto;

  ${media.isMobile`
    width: 24px;
  `}
`;

export default function SectionTitle({ title }: { title: string; }) {
  return (
    <Container>
      <LogoImage src={`${assetSource}/assets/logo-default-v2.png`} />
      <h3>
        {title}
      </h3>
    </Container>
  );
}
