const sendInfoFormTextDictionary = {
  receiverName: {
    benjaminGuide: '고생하셨습니다.\n\n\n내용증명을 받을 상대방의 이름을 알려주세요.\n\n\n만약 상대방이 회사라면 업체명을 입력해주세요.',
    inputType: '상대방 이름',
    inputPlaceholder: '상대방의 이름을 입력해주세요...',
  },
  receiverPhoneNumber: {
    benjaminGuide: '감사합니다.\n\n\n내용증명을 받을 상대방의 전화번호를 알려주세요.\n\n(예시: 01012345678)',
    inputType: '상대방 전화번호',
    inputPlaceholder: '상대방의 전화번호를 입력해주세요...',
  },
  senderName: {
    benjaminGuide: '거의 다 왔습니다.\n\n\n다음으로는 선생님의 성함을 알려주세요.',
    inputType: '내 이름',
    inputPlaceholder: '이름을 입력해주세요...',
  },
  senderPhoneNumber: {
    benjaminGuide: '알겠습니다.\n\n\n마지막으로 선생님의 전화번호를 알려주세요.\n\n(예시: 01012345678)',
    inputType: '내 전화번호',
    inputPlaceholder: '전화번호를 입력해주세요...',
  },
  bankInfoOption: {
    benjaminGuide: '환불 또는 손해배상 등으로 금액을 받으실 계좌를 추가로 입력하시겠습니까?\n\n\n계좌번호를 입력해 놓으시면 ***상대방으로부터 더 간편하게 돈을 받으실 수 있습니다.***\n\n\n상대방으로부터 ***돈을 받을 사건이 아니라면 입력하지 않으셔도 됩니다.***',
    inputType: '',
    inputPlaceholder: '',
  },
  senderBankInfo: {
    benjaminGuide: '알겠습니다.\n\n\n돈을 돌려받으실 계좌의 ***은행명***을 입력해주세요.\n\n(예시: 국민은행)\n\n\n돈을 돌려받으실 ***계좌번호***를 입력해주세요.\n\n(예시: 206-113-4324-121)',
    inputType: ['내 은행 이름', '내 계좌번호'],
    inputPlaceholder: ['은행 이름을 입력해주세요...', '계좌번호를 입력해주세요...'],
  },
};

export default sendInfoFormTextDictionary;
