import styled from 'styled-components';
import media from '../../styles/media';

const ActionContainer = styled.div`
  display: flex;
  gap: 0.5em;
  width: 100%;

  ${media.isMobile`
    gap: 0.25em;
  `}
`;

export default ActionContainer;
