import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import useConsultation from '../../hooks/useConsultation';
import useAuth from '../../hooks/useAuth';
import useLogin from '../../hooks/useLogin';

import createClaim from '../../service/createClaim';

import palette from '../../styles/palette';
import typography from '../../styles/typography';

import assetSource from '../../data/assetSource';

import ClaimGuideDescription from './ClaimGuideDescription';
import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  position: absolute;
  z-index: 8;
  bottom: 4.5em;
  left: -0.8em;
  width: 100vw;
  padding: 1em 1.5em 0.75em 1.5em;

  background-color: ${palette.containerWhite};
  border-radius: 0.625em 0.625em 0 0;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.3);

  animation: float 0.3s ease;
  @keyframes float {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const SendButton = styled.button`
  width: 100%;
  padding-block: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  background-color: ${consultationCategoryDictionary.legal.color.original};
  border-radius: 5px;
  color: ${palette.textWhite};
  ${typography.heading2Mobile}
`;

export default function MobileClaimGuideSection({
  hasDraftClaim,
  draftClaimId,
}: {
  hasDraftClaim: boolean;
  draftClaimId: string;
}) {
  const { consultationId, startLoading } = useConsultation();
  const { isLoggedIn } = useAuth();
  const { navigateLogin } = useLogin();
  const navigate = useNavigate();

  const handleClick = async () => {
    if (!isLoggedIn()) {
      navigateLogin();
      return;
    }
    startLoading();
    const claimId = hasDraftClaim ? draftClaimId : await createClaim(consultationId);
    navigate(`/send-claim?claimId=${claimId}`);
  };

  const mobileClaimGuideButton = (text: string) => (
    <SendButton className="start-send-claim-button" onClick={handleClick}>
      <img src={`${assetSource}/assets/share-icon-v2.svg`} alt="보내기" />
      <span>{text}</span>
    </SendButton>
  );

  return (
    <Container>
      <ClaimGuideDescription
        hasDraftClaim={hasDraftClaim}
      />
      <Buttons>
        {mobileClaimGuideButton(hasDraftClaim ? '이어서 작성하기' : '내용증명 보내기')}
      </Buttons>
    </Container>
  );
}
