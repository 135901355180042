import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

import consultationState from '../stores/consultationState';

import Inquiry from '../types/Inquiry';
import ConsultationCategory from '../types/ConsultationCategory';

import useConsultation from './useConsultation';
import useVisit from './useVisit';
import useAuth from './useAuth';
import useLogin from './useLogin';
import useBirthInput from './saju/useBirthInput';

import checkIsGuestTrialEnd from '../service/checkIsGuestTrialEnd';
import createConsultation from '../service/createConsultation';
import createSajuConsultation from '../service/createSajuConsultation';

export default function useMainConsultation() {
  const [consultation, setConsultation] = useRecoilState(consultationState);
  const { sajuBirth, constructSajuInquiry } = useBirthInput();
  const { isLoggedIn } = useAuth();
  const { navigateLogin } = useLogin();
  const { guestId } = useVisit();

  const { startAdvising } = useConsultation();

  const navigate = useNavigate();
  const contentMaxLength = 2000;

  const updateInquiry = useCallback((setter : (prev : Inquiry) => Inquiry) => {
    setConsultation((prev) => ({
      ...prev,
      mainConsultation: {
        ...prev.mainConsultation,
        inquiry: setter(prev.mainConsultation.inquiry),
      },
    }));
  }, [setConsultation]);

  const updateContent = useCallback((content: string) => {
    if (content.length > contentMaxLength) {
      const truncatedText = content.substring(0, contentMaxLength);
      updateInquiry((prev) => ({
        ...prev,
        content: truncatedText,
      }));
      return;
    }

    updateInquiry((prev) => ({
      ...prev,
      content,
    }));
  }, [updateInquiry]);

  // todo : 상담 타입 모두 추가하기
  const consult = async (consultationCategory: ConsultationCategory) => {
    if (!isLoggedIn()) {
      const isTrialEnd = await checkIsGuestTrialEnd(guestId);

      if (isTrialEnd) {
        navigateLogin();
        return;
      }
    }
    startAdvising();

    if (consultationCategory === 'saju') {
      const sajuInquiry = constructSajuInquiry();
      updateContent(sajuInquiry);
      const id = await createSajuConsultation({ sajuInquiry, sajuBirth, guestId });
      navigate(`/consultations/saju/${id}`);
      return;
    }

    const { content } = consultation.mainConsultation.inquiry;

    const id = await createConsultation({ consultationCategory, content, guestId });
    navigate(`/consultations/${consultationCategory}/${id}`);
  };

  const { isAdvising } = consultation;
  const { inquiry, advice } = consultation.mainConsultation;

  return {
    inquiry,
    advice,
    isAdvising: isAdvising && !advice,
    isMainConsultation: Boolean(!advice),
    contentMaxLength,
    updateContent,
    consult,
  };
}
