export default function getCurrentKoreanDate() {
  const koreaDate = new Date().toLocaleDateString('ko-KR', {
    year: 'numeric', month: '2-digit', day: '2-digit', timeZone: 'Asia/Seoul',
  });
  const [year, month, day] = koreaDate.split('.').map((part) => part.trim());

  const formattedDate = `${year}년 ${month}월 ${day}일`;

  return formattedDate;
}
