const palette = {
  primary: '#092C86',
  shade2: '#072165',
  textGrey: '#B0B8C1',
  toastGrey: '#323D4BCC',
  textDarkGrey: '#7D7D7D',
  textBlack: '#333',
  textWhite: '#fff',
  textAlert: '#D8031C',
  textCaptionGrey: '#7A7A7A',
  containerWhite: '#fff',
  containerLightGrey: '#D9D9D9',
  containerSuperLightGrey: '#F8F8F8',
  containerGrey: '#B0B8C1',
  textLightGrey: '#D9D9D9',
  textTone: '#3F5283',
  kakaoYellow: '#fee500',
  kakaoBlack: '#191919',
  tint9: '#E4E8F2',
  tint7: '#ADB9D7',
  lightTint: '#DCE0EA',
  superlightRed: 'rgba(255, 230, 230, 0.6)',
  superlightOrange: '#FFF2E6',
  superlightGreen: '#E6FFE6',
  superlightBlue: '#E6E6FF',
  superlightIndigo: '#EDE6F3',
  superlightViolet: '#F4E6FB',
  lightRed: '#FF8080',
  lightOrange: '#FFBF80',
  lightGreen: '#80FF80',
  lightBlue: '#8080FF',
  lightIndigo: '#A580C1',
  lightViolet: '#CA80E9',
  red: '#FF0000',
  orange: '#FF7F00',
  green: '#00FF00',
  blue: '#0000FF',
  indigo: '#4B0082',
  violet: '#9400D3',
  darkRed: '#CC0000',
  darkOrange: '#CC6600',
  darkGreen: '#00CC00',
  darkBlue: '#0000CC',
  darkIndigo: '#3C0068',
  darkViolet: '#7600A9',
};

export default palette;
