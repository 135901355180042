import {
  Route, Routes, BrowserRouter,
} from 'react-router-dom';
import { useEffect } from 'react';

import useVisit from './hooks/useVisit';
import useResponsive from './hooks/useResponsive';

import PageLayout from './components/layouts/PageLayout';

import LoginPage from './pages/LoginPage';
import ConsultationsPage from './pages/ConsultationsPage';
import OAuthPage from './pages/OAuthPage';
import SendClaimPage from './pages/SendClaimPage';
import ReadClaimPage from './pages/ReadClaimPage';
import LegalConsultationPage from './pages/LegalConsultationPage';
import LegalPage from './pages/LegalPage';
import SajuPage from './pages/SajuPage';
import SajuConsultationPage from './pages/SajuConsultationPage';
import HomePage from './pages/HomePage';
import ConsultationRoutePage from './pages/ConsultationRoutePage';
import MedicalPage from './pages/MedicalPage';
import AdmissionPage from './pages/AdmissionPage';
import MedicalConsultationPage from './pages/MedicalConsultationPage';
import AdmissionConsultationPage from './pages/AdmissionConsultationPage';
import MumulPage from './pages/MumulPage';
import MumulConsultationPage from './pages/MumulConsultationPage';

export default function Router() {
  const { visit } = useVisit();
  const { isMobile } = useResponsive();

  useEffect(() => {
    setTimeout(visit, 3000);
  }, [visit]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<PageLayout isHomepage withFooter />}>
          <Route path="/" element={<HomePage />} />
        </Route>
        <Route path="/" element={<PageLayout withFooter={false} isConsultationPage />}>
          <Route path="/consultations/legal" element={<LegalPage />} />
          <Route path="/consultations/saju" element={<SajuPage />} />
          <Route path="/consultations/medical" element={<MedicalPage />} />
          <Route path="/consultations/admission" element={<AdmissionPage />} />
          <Route path="/consultations/mumul" element={<MumulPage />} />
          <Route path="/consultations/:consultationId" element={<ConsultationRoutePage />} />
          <Route path="/consultations/legal/:consultationId" element={<LegalConsultationPage />} />
          <Route path="/consultations/saju/:consultationId" element={<SajuConsultationPage />} />
          <Route path="/consultations/medical/:consultationId" element={<MedicalConsultationPage />} />
          <Route path="/consultations/admission/:consultationId" element={<AdmissionConsultationPage />} />
          <Route path="/consultations/mumul/:consultationId" element={<MumulConsultationPage />} />
        </Route>
        <Route path="/" element={<PageLayout withFooter={!isMobile} isSendClaimPage />}>
          <Route path="/send-claim" element={<SendClaimPage />} />
        </Route>
        <Route path="/" element={<PageLayout withFooter={!isMobile} />}>
          <Route path="/claims/:claimId" element={<ReadClaimPage />} />
        </Route>
        <Route path="/" element={<PageLayout withFooter />}>
          <Route path="/consultations" element={<ConsultationsPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/oauth" element={<OAuthPage />} />
      </Routes>
    </BrowserRouter>
  );
}
