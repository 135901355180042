import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import palette from '../../styles/palette';

import useConsultation from '../../hooks/useConsultation';
import useAuth from '../../hooks/useAuth';
import useLogin from '../../hooks/useLogin';

import createClaim from '../../service/createClaim';

import assetSource from '../../data/assetSource';

import ClaimGuideDescription from '../consultation/ClaimGuideDescription';
import GreyNoticeText from '../common/GreyNoticeText';
import Button from '../common/Button';
import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';
import media from '../../styles/media';

const SendButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: ${consultationCategoryDictionary.legal.color.original};
  ${media.isPc`
    &:hover {
      background-color: ${consultationCategoryDictionary.legal.color.dark};
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.30);
    }    
  `}
`;

const ClaimLoading = styled(Button)`
  width: 100%;
  background-color: ${palette.containerLightGrey};
  color: ${palette.textWhite};
  pointer-events: none;
`;

const Container = styled.div<{ hasDraftClaim: boolean }>`
  width: 17.5em;
  padding: 1.5em 1em;
  position: absolute;
  top: ${({ hasDraftClaim }) => (hasDraftClaim ? '-23.75em' : '-20.75em')}; 
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5em;

  border-radius: 10px;
  background: ${palette.containerSuperLightGrey};
`;

export default function ClaimGuideSection({
  hasDraftClaim,
  draftClaimId,
}: {
  hasDraftClaim: boolean;
  draftClaimId: string;
}) {
  const { consultationId } = useParams();
  const { isLoggedIn } = useAuth();
  const { navigateLogin } = useLogin();
  const navigate = useNavigate();
  const { startLoading, isAdvising, isAnswering } = useConsultation();

  const handleClickSendClaim = async () => {
    if (!isLoggedIn()) {
      navigateLogin();
      return;
    }

    if (!consultationId) {
      return;
    }

    startLoading();
    const claimId = hasDraftClaim ? draftClaimId : await createClaim(consultationId);
    navigate(`/send-claim?claimId=${claimId}`);
  };

  const claimGuideButton = (text: string) => (
    (isAdvising || isAnswering) ? (
      <ClaimLoading disabled>답변중...</ClaimLoading>
    ) : (
      <SendButton className="start-send-claim-button" onClick={handleClickSendClaim}>
        <img src={`${assetSource}/assets/share-icon-v2.svg`} alt="보내기" />
        <span>{text}</span>
      </SendButton>
    )
  );

  return (
    <Container hasDraftClaim={hasDraftClaim}>
      <ClaimGuideDescription
        hasDraftClaim={hasDraftClaim}
      />
      {claimGuideButton(hasDraftClaim ? '이어서 작성하기' : '내용증명 보내기')}
      <GreyNoticeText>
        ※ 좋은 퀄리티의 내용증명을 쓰기 위해 추가질문을 적어도 5회 이상 하실 것을 권장합니다.
      </GreyNoticeText>
    </Container>
  );
}
