import styled from 'styled-components';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import media from '../../styles/media';

import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';

import ConsultationCategory from '../../types/ConsultationCategory';

import useConsultation from '../../hooks/useConsultation';
import useMainConsultation from '../../hooks/useMainConsultation';
import useIsFirstSajuConsultation from '../../hooks/saju/useIsFirstSajuConsultation';
import useAuth from '../../hooks/useAuth';

import Message from './Message';

const Container = styled.div`
  padding-block: 1em;
  display: flex;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  gap: 2em;
  overflow-y: scroll;
  
  &::-webkit-scrollbar {
    display: none;
  }

  ${media.isMobile`
    padding: 0.75em;
    gap: 1em;
  `}
`;

export default function ConsultationHistory({
  isStartConsultationPage,
  consultationCategory,
}: {
  isStartConsultationPage: boolean;
  consultationCategory: ConsultationCategory;
}) {
  const {
    consultation,
    isAdvising,
    isAnswering,
  } = useConsultation();

  const {
    loadIsFirstSajuConsultation,
    isFirstSajuConsultation,
  } = useIsFirstSajuConsultation();
  const { isLoggedIn } = useAuth();
  const { consultationId } = useParams();

  useEffect(() => {
    if (consultationCategory !== 'saju' || !isLoggedIn()) return;
    loadIsFirstSajuConsultation(isStartConsultationPage, consultationId);
  }, [consultationCategory,
    consultationId,
    isLoggedIn,
    isStartConsultationPage,
    loadIsFirstSajuConsultation]);

  const { inquiry } = useMainConsultation();
  const { mainConsultation, additionalConsultation } = consultation;

  const getInstruction = () => {
    if (consultationCategory === 'saju' && !isFirstSajuConsultation) {
      return consultationCategoryDictionary[consultationCategory].revisitInstruction;
    }

    return consultationCategoryDictionary[consultationCategory].welcomeInstruction;
  };

  const focusedMessageRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const scrollIntoViewBehavior = isStartConsultationPage ? 'smooth' : 'instant';

    if (focusedMessageRef.current) {
      focusedMessageRef.current.scrollIntoView({ behavior: scrollIntoViewBehavior });
    }
  }, [isAdvising, isAnswering, isStartConsultationPage]);

  if (isStartConsultationPage) {
    return (
      <Container>
        <Message
          type="answer"
          focusedMessageRef={focusedMessageRef}
          content={getInstruction()}
          category={consultationCategory}
        />
        {isAdvising && (
          <>
            <Message type="question" content={inquiry.content} category={consultationCategory} />
            <Message focusedMessageRef={focusedMessageRef} type="loading" content="" category={consultationCategory} />
          </>
        )}
      </Container>
    );
  }

  return (
    <Container>
      <Message
        type="answer"
        content={getInstruction()}
        category={consultationCategory}
      />
      <Message
        type="question"
        content={mainConsultation.inquiry.content}
        category={consultationCategory}
      />
      {isAdvising
        ? (
          <Message
            type="loading"
            content=""
            focusedMessageRef={focusedMessageRef}
            category={consultationCategory}
          />
        )
        : (
          <Message
            type="answer"
            content={mainConsultation.advice}
            focusedMessageRef={additionalConsultation.length === 0 ? focusedMessageRef : null}
            category={consultationCategory}
            isAdvice
          />
        )}
      {additionalConsultation.map((c, index) => (
        <Message
          type={c.type === 'answer' ? 'answer' : 'question'}
          content={c.content}
          focusedMessageRef={(index === additionalConsultation.length - 1 && c.type === 'answer') ? focusedMessageRef : null}
          category={consultationCategory}
        />
      ))}
      {isAnswering && <Message type="loading" content="" focusedMessageRef={focusedMessageRef} category={consultationCategory} />}
    </Container>
  );
}
