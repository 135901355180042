import ConsultationCategory from '../types/ConsultationCategory';
import bokeAPI from './bokeAPI';

export default async function createAnswer({
  consultationCategory,
  consultationId,
  question,
}: {
  consultationCategory: ConsultationCategory;
  consultationId: string;
  question: string;
}) {
  await bokeAPI.post(`/${consultationCategory}-consultations/${consultationId}/answers`, {
    content: question,
  });
}
