import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useConsultation from '../../hooks/useConsultation';

import PageLoading from '../common/PageLoading';
import ConsultationPageLayout from './ConsultationPageLayout';
import ConsultationHistory from './ConsultationHistory';
import QuestionForm from './QuestionForm';
import ConsultationCategory from '../../types/ConsultationCategory';
import useHeaderHeight from '../../hooks/useHeaderHeight';

export default function ConsultationPageContent({
  consultationCategory,
}: {
  consultationCategory: ConsultationCategory
}) {
  const {
    loadConsultation,
    isLoading,
    isAdvising,
  } = useConsultation();
  const { headerHeight } = useHeaderHeight();
  const { consultationId } = useParams();

  useEffect(() => {
    if (!consultationId) {
      // TODO: 오류 처리
      return () => {};
    }

    const loadSubscription = loadConsultation(consultationId);

    return () => {
      if (loadSubscription) {
        loadSubscription.unsubscribe();
      }
    };
  }, [consultationId, loadConsultation]);

  if (isLoading && !isAdvising) {
    return (
      <PageLoading />
    );
  }

  return (
    <ConsultationPageLayout headerHeight={headerHeight} className="consultation-page">
      <ConsultationHistory
        isStartConsultationPage={false}
        consultationCategory={consultationCategory}
      />
      <QuestionForm
        isStartConsultationPage={false}
        consultationCategory={consultationCategory}
      />
    </ConsultationPageLayout>
  );
}
