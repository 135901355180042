import Markdown from 'markdown-to-jsx';
import styled from 'styled-components';

import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';
import ConsultationCategory from '../../types/ConsultationCategory';
import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';

const Container = styled(Markdown)<{ category: ConsultationCategory }>`
  color: ${palette.textBlack};
  ${typography.body1}
  white-space: pre-wrap;

  ${media.isMobile`
    ${typography.body1}
  `}

  h1 {
    ${typography.heading2}
    ${media.isMobile`
      ${typography.heading1Mobile}
    `}
  }

  h2 {
    ${typography.heading3}
    ${media.isMobile`
      ${typography.heading2Mobile}
    `}
  }

  ul, ol {
    margin-left: 1em;
    margin-bottom: 1.5em;
    li {
      margin-bottom: 0.25em;
    }
  }

  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }

  strong {
    font-weight: 600;
    em {
      color: ${({ category }) => (consultationCategoryDictionary[category].color.dark)};
    }
  }
`;

export default function MarkdownContent({
  content, category, className = '',
}: { content: string; category: ConsultationCategory; className?: string; }) {
  return (
    <Container className={className} category={category}>
      {content}
    </Container>
  );
}

MarkdownContent.defaultProps = {
  className: '',
};
