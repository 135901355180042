import { useEffect } from 'react';

import ConsultationCategory from '../../types/ConsultationCategory';

import useMainConsultation from '../../hooks/useMainConsultation';
import useConsultation from '../../hooks/useConsultation';
import useInquiryAutoWrite from '../../hooks/useInquiryAutoWrite';

import ConsultationPageLayout from './ConsultationPageLayout';
import ConsultationHistory from './ConsultationHistory';
import QuestionForm from './QuestionForm';
import useHeaderHeight from '../../hooks/useHeaderHeight';
import BirthInfoForm from '../saju/BirthInfoForm';

export default function StartConsultationPageContent({
  consultationCategory,
}: {
  consultationCategory: ConsultationCategory
}) {
  const { updateContent, isAdvising } = useMainConsultation();
  const { initialize } = useConsultation();
  const { autoWrite } = useInquiryAutoWrite();
  const { headerHeight } = useHeaderHeight();
  useEffect(() => {
    initialize();

    if (consultationCategory !== 'saju') {
      autoWrite(updateContent);
    }
  }, [autoWrite, consultationCategory, initialize, updateContent]);

  const renderInputForm = () => {
    if (consultationCategory !== 'saju') {
      return (
        <QuestionForm
          isStartConsultationPage
          consultationCategory={consultationCategory}
        />
      );
    }

    if (isAdvising) {
      return (
        <QuestionForm
          isStartConsultationPage={false}
          consultationCategory={consultationCategory}
        />
      );
    }

    return <BirthInfoForm />;
  };

  return (
    <ConsultationPageLayout headerHeight={headerHeight}>
      <ConsultationHistory
        isStartConsultationPage
        consultationCategory={consultationCategory}
      />
      {renderInputForm()}
    </ConsultationPageLayout>
  );
}
