import palette from '../styles/palette';
import ConsultationCategory from '../types/ConsultationCategory';
import assetSource from './assetSource';

type ConsultationCategoryDictionary = {
  [Key in ConsultationCategory]: {
    welcomeInstruction: string;
    revisitInstruction: string;
    expertName: string;
    expertRole: string;
    profileImage: string;
    loadingSentences: string[];
    questionPlaceHolder: string;
    color: {
      original: string;
      light: string;
      superlight: string;
      dark: string;
    }
  }
};

export const categories: ConsultationCategory[] = ['mumul', 'legal', 'medical', 'saju', 'admission'];

const consultationCategoryDictionary: ConsultationCategoryDictionary = {
  legal: {
    welcomeInstruction: `안녕하세요, ***AI 변호사 벤자민***입니다.


저는 의뢰인님께 3가지를 약속드립니다.
***1) 24시간 실시간 상담***
***2) 100% 무료***
***3) 100% 비밀보장***


첫째, ***시간 순서*** 및 ***육하원칙으로*** 작성해주세요.
둘째, ***질문이 구체적일수록*** 상담의 질이 높아집니다.`,
    revisitInstruction: '',
    expertName: '벤자민',
    expertRole: 'AI 변호사',
    profileImage: `${assetSource}/assets/benjamin-card.png`,
    loadingSentences: [
      '대법원 판례를 다시 읽어보고 있습니다.',
      '법원에 잠시 다녀오고 있습니다.',
      '노크 AI로 사건을 정리 중입니다.',
      '리걸테크 법안이 제정되고 있습니다.',
      '관련 법령을 다시 읽어보고 있습니다.',
      '노크 AI로 사건을 분석 중입니다.',
      '유사 사례를 확인 중입니다.',
      '노크 AI로 법령을 분석 중입니다.',
      '법전을 다시 살펴보고 있습니다.',
      '노크 AI로 조언을 작성하고 있습니다.',
      '민법과 형법을 분석하고 있습니다.',
    ],
    questionPlaceHolder: '1. 시간 순으로 작성해주세요.\n2. 육하원칙에 따라 작성해주세요.\n3. 질문 내용을 자세히 작성해주세요.',
    color: {
      original: `${palette.orange}`,
      light: `${palette.lightOrange}`,
      superlight: `${palette.superlightOrange}`,
      dark: `${palette.darkOrange}`,
    },
  },
  admission: {
    welcomeInstruction: `안녕하세요, ***AI 입시 컨설턴트 창식쌤***입니다.
공부도 힘든데, 입시까지 신경쓰려니 힘드시죠?


생기부 관리부터 전공 선택과 과목 선택까지,
***입시에 관련된 무엇이든*** 물어보세요.
***합격 가능성***도 예측해드립니다.


첫째, ***희망 대학***과 ***학과***를 작성해주세요.
둘째, ***내신/모의고사 성적***을 작성해주세요.
셋째, ***학년***과 ***학교유형***을 작성해주세요.`,
    revisitInstruction: '',
    expertName: '창식쌤',
    expertRole: 'AI 입시 컨설턴트',
    profileImage: `${assetSource}/assets/changsikssam-card-v2.png`,
    loadingSentences: [
      '서울대생 생기부를 읽어보고 있습니다.',
      '그들의 생기부에 갔다오고 있습니다.',
      '노크 AI로 입시 전략을 수립 중입니다.',
      '입학 경쟁률을 조회하고 있습니다.',
      '서울대 입학처에 다녀오고 있습니다.',
      '노크 AI로 합격방법을 추론 중입니다.',
      '올해 모집요강을 정리하고 있습니다.',
      '합격생 수기를 읽어보고 있습니다.',
      '노크 AI로 입시 결과를 분석 중입니다.',
      '자동봉진 작성 요령을 검토하고 있습니다.',
      '지역균형 전형방법을 확인하고 있습니다.',
    ],
    questionPlaceHolder: '1. 학생의 목표를 구체적으로 작성해주세요.\n2. 학년과 성적을 구체적으로 작성해주세요.\n3. 질문 내용을 자세히 작성해주세요.',
    color: {
      original: `${palette.violet}`,
      light: `${palette.lightViolet}`,
      superlight: `${palette.superlightViolet}`,
      dark: `${palette.darkViolet}`,
    },
  },
  medical: {
    welcomeInstruction: `안녕하세요, ***AI 의사 닥터송***입니다.


가벼운 통증부터 타인에게 말 못할 중질환까지,
***건강에 관련된 무엇이든*** 물어보세요.
평균 ***10초 안에*** 답변드립니다.


첫째, ***현재 증상***을 알려주세요.
둘째, ***병력, 복용 중인 약물*** 등을 작성해주세요.
셋째, ***질문이 구체적일수록*** 상담의 질이 높아집니다.`,
    revisitInstruction: '',
    expertName: '닥터송',
    expertRole: 'AI 의사',
    profileImage: `${assetSource}/assets/doctorsong-card-v3.png`,
    loadingSentences: [
      '노크 AI로 치료 방법을 확인 중입니다.',
      '관련된 처방을 확인하고 있습니다.',
      '노크 AI로 유사 사례를 검토 중입니다.',
      '환자의 증상을 분석하고 있습니다.',
      '건강 상태를 평가하고 있습니다.',
      '노크 AI로 의료 데이터를 분석 중입니다.',
      '최신 의학 연구를 참고하고 있습니다.',
      '서울대병원에 다녀오고 있습니다.',
      '노크 AI로 의료 기록을 검토 중입니다.',
    ],
    questionPlaceHolder: '1. 어디가 아프신지 구체적으로 작성해주세요.\n2. 병력과 복용약물을 알려주세요.\n3. 질문 내용을 자세히 작성해주세요.',
    color: {
      original: `${palette.blue}`,
      light: `${palette.lightBlue}`,
      superlight: `${palette.superlightBlue}`,
      dark: `${palette.darkBlue}`,
    },
  },
  saju: {
    welcomeInstruction: `안녕하세요, ***AI 사주 선생 도현(導賢)***입니다.


생년월일시를 입력 후 ***풀이하기를 누르시면***
내담자님의 ***과거와 미래***를 풀이해드립니다.


구체적인 고민이나 궁금증은
***첫 사주풀이를 받으신 후***에 질문해주세요.`,
    revisitInstruction: `안녕하세요, ***AI 사주 선생 도현(導賢)***입니다.


내담자님, 이번엔 어떤 고민으로 오셨나요?
***이전 생년월일***을 입력해놓았으니 사주풀이를 먼저 받아보세요.


구체적인 고민이나 궁금증은
***첫 사주풀이를 받으신 후***에 질문해주세요.`,
    expertName: '도현(導賢)',
    expertRole: 'AI 사주 선생님',
    profileImage: `${assetSource}/assets/dohyun-card-v2.png`,
    loadingSentences: [
      '시간의 흐름 속에서 답을 찾고 있습니다.',
      '당신만의 특별한 운세를 해석하고 있습니다.',
      '노크 AI로 사주팔자를 분석 중입니다.',
      '운명의 지도를 그리고 있습니다.',
      '삼명학을 다시 읽어보고 있습니다.',
      '노크 AI로 명리학 자료를 검토 중입니다.',
      '운명의 흐름을 읽고 있습니다.',
      '사주팔자에 따른 운세를 종합하고 있습니다.',
      '노크 AI로 성격을 추론하고 있습니다.',
    ],
    questionPlaceHolder: '',
    color: {
      original: `${palette.red}`,
      light: `${palette.lightRed}`,
      superlight: `${palette.superlightRed}`,
      dark: `${palette.darkRed}`,
    },
  },
  mumul: {
    welcomeInstruction: `안녕하세요, ***엘리***입니다.


선생님에게 알맞는 ***맛집과 장소를 추천해드릴게요***.


예시:
용산역 욕쟁이 할머니 족발집
어두운 분위기의 위스키 바 추천`,
    revisitInstruction: '',
    expertName: '엘리',
    expertRole: 'AI 맛집 추천',
    profileImage: `${assetSource}/assets/elly-card-v3.png`,
    loadingSentences: [
      '정보의 바다에서 맛집을 찾는 중입니다.',
      '커피를 마시며 고민하고 있습니다.',
      '노크 AI로 질문을 분석하고 있습니다.',
      '데이터 맛집을 순례하고 있습니다',
      '맛의 퍼즐을 맞추고 있습니다.',
      '노크 AI로 정보를 수집하고 있습니다.',
      '맛 그래프를 탐색하고 있습니다.',
      '맛집 회로를 풀가동 중입니다.',
      '노크 AI로 스테이크를 굽고 있습니다.',
    ],
    questionPlaceHolder: '1. 맛집 또는 장소를 물어보세요.\n2. 선생님께 가장 적합한 맛집을 찾아드립니다.',
    color: {
      original: `${palette.indigo}`,
      light: `${palette.lightIndigo}`,
      superlight: `${palette.superlightIndigo}`,
      dark: `${palette.darkIndigo}`,
    },
  },
};

export default consultationCategoryDictionary;
