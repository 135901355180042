import styled from 'styled-components';
import useResponsive from '../../hooks/useResponsive';
import MarkdownContent from '../common/MarkdownContent';
import media from '../../styles/media';
import palette from '../../styles/palette';
import typography from '../../styles/typography';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  color: ${palette.textBlack};

  h4 {
    ${typography.heading4}
    text-align: center;

    ${media.isMobile`
      ${typography.heading2Mobile}
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    `}
  }

  p {
    ${typography.body3}

    ${media.isMobile`
      ${typography.body1}
      text-align: left;
    `}
  }
`;

export default function ClaimGuideDescription({
  hasDraftClaim,
}: {
  hasDraftClaim: boolean;
}) {
  const { isMobile } = useResponsive();
  const title = hasDraftClaim ? '내용증명을 이어서 작성해보세요.' : '3초만에 상대방에게 경고해보세요.';
  const content = hasDraftClaim
    ? '아직 작성 중인 내용증명이 있습니다.\n\n\n실제로 약 ***30%의 사건이*** 내용증명과 같은 ***경고문만으로도 해결***됩니다.\n\n\n내용증명으로 선생님의 법적 권리를 주장하고\n상대방에게 공식적으로 경고를 해보세요.'
    : '실제로 약 ***30%의 사건이*** 내용증명과 같은 ***경고문만으로도 해결***됩니다.\n\n\n내용증명으로 선생님의 법적 권리를 주장하고\n상대방에게 공식적으로 경고를 해보세요.';

  if (isMobile) {
    return (
      <Container>
        <h4>
          {title}
        </h4>
        <MarkdownContent content={content} category="legal" />
      </Container>
    );
  }
  return (
    <Container>
      <h4>{title}</h4>
      <MarkdownContent content={content} category="legal" />
    </Container>
  );
}
