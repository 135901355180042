import { ClaimSendInfo } from '../types/ClaimInfo';
import bokeAPI from './bokeAPI';

export default async function sendClaim({
  claimId,
  sendInfo,
} : {
  claimId: string;
  sendInfo: ClaimSendInfo;
}) {
  const { data } = await bokeAPI.post(`/claims/${claimId}/send`, {
    sendInfo,
  });

  return data;
}
