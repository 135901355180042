import { atom } from 'recoil';
import ClaimInfo from '../types/ClaimInfo';

const claimInfoState = atom<ClaimInfo>({
  key: 'claimInfoState',
  default: {
    id: '',
    title: '',
    content: '',
    status: 'init',
  },
});

export default claimInfoState;
