import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import requestLogin from '../service/requestLogin';
import saveAccessToken from '../service/saveAccessToken';

import useConsultation from './useConsultation';
import useVisit from './useVisit';
import { categories } from '../data/consultationCategoryDictionary';

export default function useLogin() {
  const navigate = useNavigate();
  const { consultation } = useConsultation();
  const { guestId, removeGuestId } = useVisit();
  const location = useLocation();

  const login = useCallback(async (authorizationCode: string) => {
    const accessToken = await requestLogin(authorizationCode, guestId);
    removeGuestId();
    saveAccessToken(accessToken);
  }, [guestId, removeGuestId]);

  const navigateLogin = useCallback(() => {
    const consultationId = consultation.id;
    const loginQuery = [
      consultationId ? `consultationId=${consultationId}` : '',
      categories.some((category) => `/consultations/${category}` === location.pathname)
    && `category=${location.pathname.split('/').pop()}`,
    ].filter(Boolean).join('&');

    navigate(`/login${loginQuery ? `?${loginQuery}` : ''}`);
  }, [consultation.id, location, navigate]);

  return {
    login,
    navigateLogin,
  };
}
