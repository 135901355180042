import { ClaimStatus } from '../../types/ClaimInfo';
import ClaimControllerLoading from './ClaimControllerLoading';
import ClaimEditForm from './ClaimEditForm';
import ClaimSendInfoForm from './ClaimSendInfoForm';
import ClaimShare from './ClaimShare';

export default function ClaimController({
  status,
}: {
  status: ClaimStatus;
}) {
  if (status === 'init' || status === 'modifying') {
    return <ClaimControllerLoading />;
  }

  if (status === 'edit') {
    return <ClaimEditForm />;
  }

  if (status === 'send') {
    return <ClaimSendInfoForm />;
  }

  if (status === 'done') {
    return <ClaimShare />;
  }

  return null;
}
