import styled from 'styled-components';

import typography from '../styles/typography';
import palette from '../styles/palette';
import media from '../styles/media';

import ClaimDetail from '../types/ClaimDetail';

import { CompletedAtDate, DateAndName, LawyerName } from './send-claim/ClaimContent';

const Container = styled.div`
  display: flex;
  padding: 1em;
  gap: 1em;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: 1px solid ${palette.containerLightGrey};
  align-self: stretch;
  

  ${media.isMobile`
    padding: 1em 0.5em;
    border: 0;
    border-radius: 0;
  `}
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  
  h3 {
    ${typography.heading3}
  }
  
  ${media.isMobile`
    h3 {
      ${typography.heading2Mobile}
    }
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  
  p {
    ${typography.body1}
    color: ${palette.textBlack};
    padding: 0.5em;
    white-space: pre-wrap;
    align-self: stretch;

    &:first-of-type {
      ${typography.body1Bold}
    }
  }
`;

export default function ClaimDetailSection({
  claimDetail,
  className = '',
}: {
  claimDetail: ClaimDetail;
  className?: string;
}) {
  const [foreword, claimContent] = claimDetail.content.split('\n\n\n');
  const claimContentParts = (claimContent || '').split('\n\n');

  return (
    <Container className={className}>
      <Title>
        <h3>{claimDetail.title}</h3>
      </Title>
      <Content>
        <p>
          {foreword}
        </p>
        {claimContentParts.map((text) => (
          <p key={text}>{text}</p>
        ))}
      </Content>
      <DateAndName>
        <CompletedAtDate>{claimDetail.completedAt}</CompletedAtDate>
        <LawyerName>AI 변호사 벤자민</LawyerName>
      </DateAndName>
    </Container>
  );
}

ClaimDetailSection.defaultProps = {
  className: '',
};
