import ConsultationCategory from '../types/ConsultationCategory';
import bokeAPI from './bokeAPI';

export default async function createConsultation({
  consultationCategory,
  content,
  guestId,
}: {
  consultationCategory: ConsultationCategory;
  content: string;
  guestId: string;
}) {
  const { data } = await bokeAPI.post(`/${consultationCategory}-consultations`, {
    inquiryContent: content,
    guestId,
  });

  return data;
}
