import styled from 'styled-components';

import typography from '../../styles/typography';
import palette from '../../styles/palette';
import media from '../../styles/media';

import useResponsive from '../../hooks/useResponsive';

import { AnswerSidebar } from '../Sidebar';
import Button from '../common/Button';
import ClaimActionButton from '../ClaimActionButton';
import MarkdownContent from '../common/MarkdownContent';

const Container = styled.div`
  display: flex;
  gap: 0.5em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5em;

  h4 {
    color: ${palette.textBlack};
    ${typography.heading4}

    ${media.isMobile`
      ${typography.heading4Mobile}
    `}
  }

  p {
    color: ${palette.textBlack};
    ${typography.body1}
    white-space: pre-wrap;

    ${media.isMobile`
      ${typography.body1}
    `}
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
`;

const RewriteButton = styled(Button)`
  flex: 1;
`;

const KeepButton = styled(RewriteButton)`
  color: ${palette.textDarkGrey};
  background-color: ${palette.lightTint};

  &:hover {
    background-color: ${palette.tint7};
  }
`;

export default function UnknownInfoGuide({ keep, rewrite, infoType }:{
  keep: () => void;
  rewrite: () => void;
  infoType: 'name' | 'phoneNumber';
}) {
  const { isMobile } = useResponsive();

  const guideSentence = infoType === 'name'
    ? '상대방의 이름을 모른다면 수신인을 [불명]으로 처리하게 됩니다.\n\n\n괜찮으시겠습니까?\n&nbsp;'
    : '상대방의 번호를 모른다면 전화번호를 [불명]으로 처리하게 됩니다.\n\n\n또한, 저희가 내용증명을 발송하는 대신 ***선생님께서 직접 발송 또는 공유***하셔야 합니다.\n\n\n괜찮으시겠습니까?\n&nbsp;';

  return (
    <Container>
      <AnswerSidebar />
      <Content>
        <h4>벤자민 - AI 변호사</h4>
        <MarkdownContent content={guideSentence} category="legal" />
        {isMobile
          ? <ClaimActionButton status="userUnknownInfo" onClickLeft={keep} onClickRight={rewrite} />
          : (
            <Buttons>
              <KeepButton type="button" onClick={keep}>
                네, 괜찮아요
              </KeepButton>
              <RewriteButton type="button" onClick={rewrite}>
                다시 쓸게요
              </RewriteButton>
            </Buttons>
          )}
      </Content>
    </Container>
  );
}
