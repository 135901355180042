import styled from 'styled-components';

import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';

const Button = styled.button`
  padding: 0.5em 1em;
  background-color: ${palette.primary};
  border-radius: 5px;
  color: ${palette.textWhite};
  ${typography.body1Bold}
  cursor: pointer;
  
  ${media.isPc`
    &:hover {
      background-color: ${palette.shade2};
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.30);
    }    
  `}
`;

export default Button;
