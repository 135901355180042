import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { ChangeEvent } from 'react';

import ConsultationCategory from '../../types/ConsultationCategory';
import assetSource from '../../data/assetSource';
import media from '../../styles/media';
import palette from '../../styles/palette';

import useAuth from '../../hooks/useAuth';
import useConsultation from '../../hooks/useConsultation';
import useMainConsultation from '../../hooks/useMainConsultation';
import useAdditionalConsultation from '../../hooks/useAdditionalConsultation';

import QuestionInput from './QuestionInput';
import QuestionSubmit from './QuestionSubmit';
import LegalQuestionForm from '../legal/LegalQuestionForm';
import InduceLoginSection from './InduceLoginSection';
import PageLoading from '../common/PageLoading';
import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';

const Container = styled.div<{ noBorderTop: boolean; }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5em;
  padding-top: 0.5em;
  border-top: ${({ noBorderTop }) => (noBorderTop ? 'none' : `1px solid ${palette.containerLightGrey}`)};

  ${media.isMobile`
    flex-direction: column-reverse;
    border-top: 1px solid ${palette.containerLightGrey};
    padding: 0.5em 0.75em;
  `}
`;

export default function QuestionForm({
  isStartConsultationPage,
  consultationCategory,
}: {
  isStartConsultationPage: boolean;
  consultationCategory: ConsultationCategory;
}) {
  const {
    consultation,
    isLoading,
    isAdvising,
    isAnswering,
  } = useConsultation();

  const {
    consult, updateContent, inquiry,
  } = useMainConsultation();

  const {
    ask,
    updateQuestion,
    question,
  } = useAdditionalConsultation();

  const { additionalConsultation } = consultation;
  const { isQuestionTrialEnd } = useAuth();

  const handleChangeInquiry = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    updateContent(value);
  };

  const handleChangeQuestion = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateQuestion(event.target.value);
  };

  const handleClickQuestionSubmit = () => {
    ask(consultationCategory, consultation.id);
  };

  if (isLoading && !isAdvising) {
    return (
      <PageLoading />
    );
  }

  const startConsultationMinRow = () => {
    if (isAdvising) return 1;
    if (consultationCategory === 'mumul') return 2;
    return 3;
  };

  if (isStartConsultationPage) {
    return (
      <Container noBorderTop={false}>
        <QuestionInput>
          <TextareaAutosize
            maxRows={5}
            minRows={startConsultationMinRow()}
            placeholder={isAdvising
              ? '답변을 생각중입니다...'
              : consultationCategoryDictionary[consultationCategory].questionPlaceHolder}
            value={isAdvising ? '' : inquiry.content}
            disabled={isAdvising}
            onChange={handleChangeInquiry}
          />
          <QuestionSubmit
            inactive={inquiry.content === '' || isAdvising}
            consultationCategory={consultationCategory}
          >
            <button id="inquiry-button" type="button" onClick={() => consult(consultationCategory)}>
              {inquiry.content
                ? <img src={`${assetSource}/assets/send-button.svg`} alt="보내기 버튼" />
                : <img src={`${assetSource}/assets/send-button-inactive.svg`} alt="보내기 버튼 비활성화" />}
            </button>
          </QuestionSubmit>
        </QuestionInput>
      </Container>
    );
  }

  if (consultationCategory === 'legal') {
    return (
      <LegalQuestionForm />
    );
  }

  return (
    <Container noBorderTop={isQuestionTrialEnd(additionalConsultation)}>
      {isQuestionTrialEnd(additionalConsultation)
          && <InduceLoginSection />}
      <QuestionInput>
        <TextareaAutosize
          maxRows={3}
          minRows={1}
          placeholder={isAnswering || isAdvising ? '답변을 생각중입니다...' : '질문을 입력하세요...'}
          disabled={isAnswering || isAdvising || isQuestionTrialEnd(additionalConsultation)}
          value={question}
          onChange={handleChangeQuestion}
        />
        <QuestionSubmit
          inactive={isAnswering || isAdvising || question === ''}
          consultationCategory={consultationCategory}
        >
          <button type="button" onClick={handleClickQuestionSubmit}>
            {question
              ? <img src={`${assetSource}/assets/send-button.svg`} alt="보내기 버튼" />
              : <img src={`${assetSource}/assets/send-button-inactive.svg`} alt="보내기 버튼 비활성화" />}
          </button>
        </QuestionSubmit>
      </QuestionInput>
    </Container>
  );
}
