import styled from 'styled-components';

import palette from '../../styles/palette';
import typography from '../../styles/typography';
import media from '../../styles/media';

import assetSource from '../../data/assetSource';

const KakaoShareButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${palette.kakaoYellow};
  color: ${palette.kakaoBlack};
  border-radius: 5px;
  width: 294px;
  height: 50px;
  cursor: pointer;

  ${media.isMobile`
    width: 100%;
  `}

  &:hover {
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.30);
  }

  img {
    width: 36px;
    height: auto; 
  }
  
  span { 
    ${typography.body1Bold}
    width: 222px;
  }

  ${media.isMobile`
    span {
      ${typography.body1Bold}
    }
  `}
`;

const SelfShareButton = styled(KakaoShareButton)`
  background-color: ${palette.primary};
  color: ${palette.textWhite};
  
  &:hover {
    background-color: ${palette.shade2};
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.30);
  }

  img {
    width: 24px;
    height: auto; 
  }
`;

export default function ClaimShareButton({
  shareTo, onClick,
} : {
  shareTo: 'kakao' | 'self';
  onClick: () => void;
}) {
  if (shareTo === 'kakao') {
    return (
      <KakaoShareButton id="share-claim-button" className="kakao-share" type="button" onClick={onClick}>
        <img
          src={`${assetSource}/assets/kakao-icon.png`}
          alt="카카오"
        />
        <span>카카오로 전달하기</span>
      </KakaoShareButton>
    );
  }

  if (shareTo === 'self') {
    return (
      <SelfShareButton id="share-claim-button" className="self-share" type="button" onClick={onClick}>
        <img
          src={`${assetSource}/assets/share-link.svg`}
          alt="링크"
        />
        <span>직접 전달하기</span>
      </SelfShareButton>
    );
  }
}
