import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import requestModifyClaim from '../service/requestModifyClaim';
import useClaimInfo from './useClaimInfo';
import isClaimModifiedState from '../stores/isClaimModifiedState';
import completeModifyClaim from '../service/completeModifyClaim';
import useResponsive from './useResponsive';

export default function useClaimModify() {
  const requestMaxLength = 200;
  const recommendedRequestLength = 30;
  const { loadClaimInfo, updateClaimStatus, claimInfo } = useClaimInfo();
  const [requestMessage, setRequestMessage] = useState<string>('');
  const [isClaimModified, setIsClaimModified] = useRecoilState(isClaimModifiedState);

  const { isMobile } = useResponsive();

  const modifyAgainMessage = isMobile
    ? '수정이 완료되었습니다.\n\n\n이대로 보내고 싶으시면,\n***취소를 누른 뒤 수정 완료하기***를 눌러주세요.\n\n\n추가 요청사항이 있으면 말씀해주세요.'
    : '수정이 완료되었습니다.\n\n\n이대로 보내고 싶으시면,\n***오른쪽 위 수정완료 버튼***을 눌러주세요.\n\n\n추가 요청사항이 있으면 말씀해주세요.';

  const welcomeMessage = isMobile
    ? '선생님의 상담 내용을 읽고 내용증명을 작성해보았습니다.\n\n\n이대로 보내고 싶으시면,\n***취소를 누른 뒤 수정 완료하기***를 눌러주세요.\n\n\n수정이 필요하시면,\n아래에 ***요청사항을 구체적으로*** 적어주세요.'
    : '선생님의 상담 내용을 읽고 내용증명을 작성해보았습니다.\n\n\n이대로 보내고 싶으시면,\n***오른쪽 위 수정완료 버튼***을 눌러주세요.\n\n\n수정이 필요하시면,\n아래에 ***요청사항을 구체적으로*** 적어주세요.';

  const guideMessage = isClaimModified ? modifyAgainMessage : welcomeMessage;

  const modifyClaim = useCallback(async () => {
    const claimId = claimInfo.id;

    updateClaimStatus('modifying');

    await requestModifyClaim({
      claimId,
      requestMessage,
    });

    loadClaimInfo(claimId);

    setIsClaimModified(true);
  }, [claimInfo.id, loadClaimInfo, requestMessage, setIsClaimModified, updateClaimStatus]);

  const doneModify = useCallback(async () => {
    const claimId = claimInfo.id;

    updateClaimStatus('send');

    await completeModifyClaim({
      claimId,
    });
  }, [claimInfo.id, updateClaimStatus]);

  const updateRequestMessage = useCallback((content: string) => {
    setRequestMessage(content);
  }, []);

  return {
    modifyClaim,
    doneModify,
    guideMessage,
    requestMessage,
    updateRequestMessage,
    requestMaxLength,
    recommendedRequestLength,
  };
}
