import { useCallback, useState } from 'react';
import checkIsClaimSendable from '../service/checkIsClaimSendable';

export default function useIsClaimSendable() {
  const [isClaimSendable, setIsClaimSendable] = useState<boolean>(false);
  const [isClaimSendableLoading, setIsClaimSendableLoading] = useState<boolean>(false);

  const loadIsClaimSendable = useCallback(async (consultationId: string) => {
    setIsClaimSendableLoading(true);

    const response = await checkIsClaimSendable(consultationId);
    setIsClaimSendable(response);

    setIsClaimSendableLoading(false);
  }, []);

  return {
    loadIsClaimSendable,
    isClaimSendableLoading,
    isClaimSendable,
  };
}
