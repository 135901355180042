import { atom } from 'recoil';

import { ClaimSendInfo } from '../types/ClaimInfo';

const claimSendInfoState = atom<ClaimSendInfo>({
  key: 'claimSendInfoState',
  default: {
    receiver: {
      name: '',
      hasName: true,
      phoneNumber: '',
      hasPhoneNumber: true,
    },
    sender: {
      name: '',
      phoneNumber: '',
      bankName: '',
      bankAccount: '',
    },
  },
});

export default claimSendInfoState;
