import styled from 'styled-components';
import { createPortal } from 'react-dom';
import { RefCallback, useCallback, useState } from 'react';
import palette from '../styles/palette';
import typography from '../styles/typography';

import assetSource from '../data/assetSource';

import ClaimShareButton from './common/ClaimShareButton';

const Container = styled.div<{ status: Status }>`
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100vw;
  display: flex;

  flex-direction: ${({ status }) => (status === 'shareClaim' ? 'column' : 'row')};
  gap: 0.5em;
  padding: 0.5em;
  
  border-top: 1px solid ${palette.containerLightGrey};
  background-color: ${palette.containerWhite};
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.30);
`;

const buttonBackgroundColor: Record<Status, string> = {
  isLoading: palette.containerLightGrey,
  isClaimEdit: palette.primary,
  isClaimDetailPage: palette.primary,
  isInputEmpty: palette.containerLightGrey,
  requestModify: palette.primary,
  userUnknownInfo: palette.primary,
  shareClaim: palette.primary,
};

const RightButton = styled.button<{ status : Status }>`
  flex: 1;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding-block: 16px;

  border-radius: 5px;
  ${typography.heading2Mobile}

  color: ${palette.textWhite};
  background-color: ${({ status }) => buttonBackgroundColor[status]};
`;

const LeftButton = styled(RightButton)`
  color: ${palette.primary};
  background-color: ${palette.tint9};
`;

const KeepButton = styled(RightButton)`
  max-width: 140px;
  color: ${palette.textDarkGrey};
  background-color: ${palette.lightTint};
`;

const Blank = styled.div<{ height: number }>`
  width: 100vw;
  height: ${({ height }) => `${height}px`};
`;

type Status = 'isLoading' | 'isClaimEdit' | 'isClaimDetailPage' | 'isInputEmpty' | 'requestModify' | 'userUnknownInfo' | 'shareClaim';

function ClaimActionButton({
  status, onClickLeft, onClickRight, containerRef,
}: {
  status: Status;
  onClickLeft: () => void;
  onClickRight: () => void;
  containerRef: RefCallback<HTMLDivElement>;
}) {
  if (status === 'isLoading') {
    return (
      <Container ref={containerRef} status={status}>
        <RightButton type="button" disabled status={status}>
          내용증명 작성 중...
        </RightButton>
      </Container>
    );
  }

  if (status === 'isClaimEdit') {
    return (
      <Container ref={containerRef} status={status}>
        <LeftButton type="button" status={status} onClick={onClickLeft}>
          <img src={`${assetSource}/assets/pen-primary.svg`} alt="내용증명 수정 요청하기" />
          <span>
            수정 요청하기
          </span>
        </LeftButton>
        <RightButton id="modify-claim-end-button" type="button" status={status} onClick={onClickRight}>
          <img src={`${assetSource}/assets/share-icon-v2.svg`} alt="내용증명 수정 완료하기" />
          <span>
            수정 완료하기
          </span>
        </RightButton>
      </Container>
    );
  }

  if (status === 'isClaimDetailPage') {
    return (
      <Container ref={containerRef} status={status}>
        <RightButton id="inquiry-me-too-button" type="button" status={status} onClick={onClickRight}>
          <img src={`${assetSource}/assets/pen_white.svg`} alt="질문하러 가기" />
          <span>
            내 사건에 대해 무료법률상담 받기
          </span>
        </RightButton>
      </Container>
    );
  }

  if (status === 'isInputEmpty') {
    return (
      <Container ref={containerRef} status={status}>
        <RightButton type="button" disabled status={status}>
          <img src={`${assetSource}/assets/pen_white.svg`} alt="질문 제출하기" />
          <span>
            내용을 입력해주세요
          </span>
        </RightButton>
      </Container>
    );
  }

  if (status === 'requestModify') {
    return (
      <Container ref={containerRef} status={status}>
        <RightButton id="modify-claim-button" type="button" status={status} onClick={onClickRight}>
          <img src={`${assetSource}/assets/pen_white.svg`} alt="질문하러 가기" />
          <span>
            수정 요청하기
          </span>
        </RightButton>
      </Container>
    );
  }
  if (status === 'userUnknownInfo') {
    return (
      <Container ref={containerRef} status={status}>
        <KeepButton type="button" status={status} onClick={onClickLeft}>
          <span>
            네, 괜찮아요
          </span>
        </KeepButton>
        <RightButton type="button" status={status} onClick={onClickRight}>
          <span>
            다시 쓸게요
          </span>
        </RightButton>
      </Container>
    );
  }

  if (status === 'shareClaim') {
    return (
      <Container ref={containerRef} status={status}>
        <ClaimShareButton shareTo="kakao" onClick={onClickLeft} />
        <ClaimShareButton shareTo="self" onClick={onClickRight} />
      </Container>
    );
  }
}

export default function Proxy({
  status, onClickLeft = () => {}, onClickRight,
}: {
  status: Status;
  onClickLeft?: () => void;
  onClickRight: () => void;
}) {
  const [containerHeight, setContainerHeight] = useState(0);
  const containerRef = useCallback((element: HTMLDivElement | null) => {
    if (!element) {
      return;
    }
    setContainerHeight(element.offsetHeight);
  }, []);
  return (
    <>
      <ClaimActionButton
        containerRef={containerRef}
        status={status}
        onClickLeft={onClickLeft}
        onClickRight={onClickRight}
      />
      {createPortal(<Blank height={containerHeight} />, document.body)}
    </>
  );
}

Proxy.defaultProps = {
  onClickLeft: () => {},
};
