import styled from 'styled-components';
import { useState } from 'react';
import { RemoveScroll } from 'react-remove-scroll';

import palette from '../../styles/palette';
import typography from '../../styles/typography';

import useResponsive from '../../hooks/useResponsive';
import useClaimModify from '../../hooks/useClaimModify';

import ClaimActionButton from '../ClaimActionButton';
import ClaimEditFormSection from './ClaimEditFormSection';

const MobileModalLayout = styled.div`
  position: fixed;
  z-index: 8;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 90svh;

  background-color: ${palette.containerWhite};
  border-radius: 0.625em 0.625em 0 0;
  border: 0.5px solid ${palette.containerLightGrey};
  box-shadow: 0 -0.125em 0.625em 0 rgba(0, 0, 0, 0.5);

  animation: float 0.3s ease;
  @keyframes float {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em 1em;
  border-bottom: 0.03125em solid ${palette.containerLightGrey};
`;

const CloseButton = styled.button`
  padding: 0.5em 0.75em;
  color: ${palette.primary};
  background-color: transparent;
  ${typography.body1Button}
`;

const Background = styled.div`
  position: fixed;
  z-index: 7;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.7);
`;

export default function ClaimEditForm() {
  const {
    doneModify,
  } = useClaimModify();

  const { isMobile } = useResponsive();

  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const onClose = () => setIsEditFormOpen(false);

  if (isMobile) {
    return (
      <>
        <ClaimActionButton status="isClaimEdit" onClickLeft={() => setIsEditFormOpen(true)} onClickRight={doneModify} />
        {isEditFormOpen && (
        <>
          <RemoveScroll>
            <MobileModalLayout>
              <ModalHeader>
                <CloseButton onClick={onClose}>취소</CloseButton>
              </ModalHeader>
              <ClaimEditFormSection />
            </MobileModalLayout>
          </RemoveScroll>
          <Background />
        </>
        )}
      </>
    );
  }

  return (
    <ClaimEditFormSection />
  );
}
