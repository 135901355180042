import styled from 'styled-components';

import { useLocation, useNavigate } from 'react-router-dom';

import { useEffect } from 'react';
import sendInfoFormTextDictionary from '../../data/sendInfoFormTextDictionary';

import palette from '../../styles/palette';
import typography from '../../styles/typography';

import useClaimSendInfo from '../../hooks/useClaimSendInfo';
import useClaimInfo from '../../hooks/useClaimInfo';

import sendClaim from '../../service/sendClaim';

import Answer from '../Answer';
import { SmallAnswerIconSidebar } from '../Sidebar';
import UnknownInfoGuide from './UnknownInfoGuide';
import SendInfoStepButtons from './SendInfoStepButtons';
import media from '../../styles/media';
import InfoCheckBox from '../common/InfoCheckBox';

const Container = styled.div`
  width: 25em;
  padding: 1.5em 1em;
  border-radius: 10px;
  border: 1px solid ${palette.containerLightGrey};

  display: flex;
  flex-direction: column;
  gap: 1.5em;

  ${media.isMobile`
    width: 100vw;
    padding: 0.5em 0.75em;
    border-radius: 0;
    border: none;
    gap: 1em;
  `}
`;

const InputSection = styled.div`
  display: flex;
  gap: 0.5em;
`;

const InputBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  flex: 1;

  > p {
    color: ${palette.textAlert};
    ${typography.caption}
  }
`;

const Input = styled.input`
  padding: 0.5em;
  border-radius: 5px;
  border: 1px solid ${palette.containerLightGrey};

  ${typography.body1}
  
  &::placeholder {
    color: ${palette.textGrey};
  }
`;

const InputHead = styled.div`
  height: 2.25em;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  h4 {
    ${typography.heading4}

    ${media.isMobile`
      ${typography.heading4Mobile}
    `}
  }
`;

export default function ClaimSendInfoForm() {
  const {
    sendInfo,
    sendInfoStep,
    isValidPhoneNumber,
    backStep,
    nextStep,
    updateReceiverName,
    updateReceiverHasName,
    updateReceiverHasPhoneNumber,
    updateReceiverPhoneNumber,
    updateSenderName,
    updateSenderPhoneNumber,
    updateSenderBankInfo,
    initializeSendInfo,
  } = useClaimSendInfo();

  useEffect(() => {
    initializeSendInfo();
  }, [initializeSendInfo]);

  const { loadClaimInfo, updateClaimStatus, claimInfo } = useClaimInfo();

  const navigate = useNavigate();
  const location = useLocation();

  const { receiver, sender } = sendInfo;

  const handleClickSubmit = async () => {
    updateClaimStatus('sending');

    const { hasPhoneNumber } = await sendClaim({ claimId: claimInfo.id, sendInfo });

    const params = new URLSearchParams(location.search);
    params.set('hasPhoneNumber', hasPhoneNumber);

    loadClaimInfo(claimInfo.id);

    navigate(`?${params.toString()}`);
  };

  const { benjaminGuide, inputType, inputPlaceholder } = sendInfoFormTextDictionary[sendInfoStep];
  const sendInfoFormValueDictionary = {
    receiverName: {
      value: receiver.name,
      updateValue: updateReceiverName,
      hasValue: receiver.hasName,
      updateHasValue: updateReceiverHasName,
    },
    receiverPhoneNumber: {
      value: receiver.phoneNumber,
      updateValue: updateReceiverPhoneNumber,
      hasValue: receiver.hasPhoneNumber,
      updateHasValue: updateReceiverHasPhoneNumber,
    },
    senderName: {
      value: sender.name,
      updateValue: updateSenderName,
      hasValue: true,
      updateHasValue: () => {},
    },
    senderPhoneNumber: {
      value: sender.phoneNumber,
      updateValue: updateSenderPhoneNumber,
      hasValue: true,
      updateHasValue: () => {},
    },
    bankInfoOption: {
      value: '',
      updateValue: () => {},
      hasValue: true,
      updateHasValue: () => {},
    },
    senderBankInfo: {
      value: [sender.bankName, sender.bankAccount],
      updateValue: updateSenderBankInfo,
      hasValue: true,
      updateHasValue: () => {},
    },
  };

  const isReceiverInfoStep = ['receiverName', 'receiverPhoneNumber'].includes(sendInfoStep);
  const isNameStep = ['receiverName', 'senderName'].includes(sendInfoStep);
  const isBankInfoStep = ['bankInfoOption', 'senderBankInfo'].includes(sendInfoStep);
  const isBankInfoOptionStep = sendInfoStep === 'bankInfoOption';
  const isSenderBankInfoStep = sendInfoStep === 'senderBankInfo';
  const isUserKnowInfo = sendInfoFormValueDictionary[sendInfoStep].hasValue;

  return (
    <Container className={sendInfoStep}>
      <Answer
        content={benjaminGuide}
        isAdvice={false}
      />
      {isBankInfoOptionStep || (
        isSenderBankInfoStep
          ? (
            <>
              <InputSection>
                <SmallAnswerIconSidebar />
                <InputBody>
                  <InputHead>
                    <h4>{inputType[0]}</h4>
                  </InputHead>
                  <Input
                    type="text"
                    placeholder={inputPlaceholder[0]}
                    value={sendInfoFormValueDictionary[sendInfoStep].value[0]}
                    disabled={!sendInfoFormValueDictionary[sendInfoStep].hasValue}
                    onChange={(e) => sendInfoFormValueDictionary[sendInfoStep]
                      .updateValue(e.target.value, 'name')}
                  />
                </InputBody>
              </InputSection>
              <InputSection>
                <SmallAnswerIconSidebar />
                <InputBody>
                  <InputHead>
                    <h4>{inputType[1]}</h4>
                  </InputHead>
                  <Input
                    type="text"
                    placeholder={inputPlaceholder[1]}
                    value={sendInfoFormValueDictionary[sendInfoStep].value[1]}
                    disabled={!sendInfoFormValueDictionary[sendInfoStep].hasValue}
                    onChange={(e) => sendInfoFormValueDictionary[sendInfoStep]
                      .updateValue(e.target.value, 'account')}
                  />
                </InputBody>
              </InputSection>
            </>
          )
          : (
            <InputSection>
              <SmallAnswerIconSidebar />
              <InputBody>
                <InputHead>
                  <h4>{inputType}</h4>
                  {isReceiverInfoStep && (
                    <InfoCheckBox
                      isChecked={!sendInfoFormValueDictionary[sendInfoStep].hasValue}
                      handleChangeChecked={(isChecked) => sendInfoFormValueDictionary[sendInfoStep]
                        .updateHasValue(!isChecked)}
                      infoText={sendInfoStep === 'receiverName' ? '상대방 이름을 몰라요' : '상대방 전화번호를 몰라요'}
                      infoType="alert"
                    />
                  )}
                </InputHead>
                <Input
                  type="text"
                  placeholder={inputPlaceholder as string}
                  value={sendInfoFormValueDictionary[sendInfoStep].value}
                  disabled={!sendInfoFormValueDictionary[sendInfoStep].hasValue}
                  onChange={(e) => sendInfoFormValueDictionary[sendInfoStep]
                    .updateValue(e.target.value)}
                />
                {(!isValidPhoneNumber && !isNameStep
                  ? (
                    <p>유효한 전화번호가 아닙니다.</p>
                  )
                  : null)}
              </InputBody>
            </InputSection>
          )
      )}
      {
          isUserKnowInfo
            ? (
              <SendInfoStepButtons
                handleClickLeft={isBankInfoOptionStep ? nextStep : backStep}
                handleClickRight={
                  (isBankInfoStep)
                    ? handleClickSubmit
                    : nextStep
                }
                sendInfoStep={sendInfoStep}
                inactive={!isBankInfoOptionStep && (typeof sendInfoFormValueDictionary[sendInfoStep].value === 'string'
                  ? sendInfoFormValueDictionary[sendInfoStep].value === ''
                  : sendInfoFormValueDictionary[sendInfoStep].value.some((v) => v === ''))}
              />
            )
            : (
              <UnknownInfoGuide
                keep={nextStep}
                rewrite={() => sendInfoFormValueDictionary[sendInfoStep].updateHasValue(true)}
                infoType={sendInfoStep === 'receiverName' ? 'name' : 'phoneNumber'}
              />
            )
        }
    </Container>
  );
}
