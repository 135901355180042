import { useCallback } from 'react';

import useModal from './useModal';
import useQueryParams from './useQueryParams';

import KinWelcomeModal from '../components/modal/KinWelcomeModal';

export default function useInquiryAutoWrite() {
  const { openModal } = useModal();
  const { naverInquiry } = useQueryParams();

  const autoWrite = useCallback((write : (content: string) => void) => {
    const inquiryContent = naverInquiry;

    if (!inquiryContent) return;

    openModal({
      Component: KinWelcomeModal,
    });
    write(inquiryContent);
  }, [naverInquiry, openModal]);

  return {
    autoWrite,
  };
}
