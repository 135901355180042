import { useCallback, useState } from 'react';
import fetchClaimInfo from '../service/fetchClaimInfo';
import ClaimDetail from '../types/ClaimDetail';

export default function useReadClaim() {
  const [claimDetail, setClaimDetail] = useState<ClaimDetail>({
    title: '',
    content: '',
    completedAt: null,
  });

  const loadClaimDetail = useCallback(async (claimId: string) => {
    const claimInfo = await fetchClaimInfo(claimId);

    setClaimDetail({
      title: claimInfo.title,
      content: claimInfo.content,
      completedAt: claimInfo.completedAt,
    });
  }, []);

  return {
    loadClaimDetail,
    claimDetail,
  };
}
