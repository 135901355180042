import { ClaimStatus } from '../types/ClaimInfo';
import bokeAPI from './bokeAPI';

interface FetchClaimInfoResponse {
  title: string;
  content: string;
  status: ClaimStatus;
  completedAt: string | null;
}

export default async function fetchClaimInfo(id: string): Promise<FetchClaimInfoResponse> {
  const { data } = await bokeAPI.get(`/claims/${id}`);
  return data;
}
