import styled from 'styled-components';
import palette from '../../styles/palette';
import media from '../../styles/media';
import typography from '../../styles/typography';
import { AnswerSidebar } from '../Sidebar';
import PCSubmitButton from '../common/PCSubmitButton';
import ProgressSection from '../answerLoading/ProgressSection';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25em;
  height: 37.375em;
  padding: 1.5em 1em;
  border-radius: 10px;
  border: 1px solid ${palette.containerLightGrey};

  ${media.isMobile`
    gap: 1em;
  `}
`;

const Instruction = styled.div`
  display: flex;
  gap: 0.5em;
`;

const Content = styled.div`
  height: 321px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.25em;

  white-space: pre-wrap;

  h4 {
    color: ${palette.textBlack};
    ${typography.heading4}

    ${media.isMobile`
      ${typography.heading4Mobile}
    `}
  }
`;

const ButtonArea = styled.div`
  align-self: flex-end;
`;

export default function ClaimControllerLoading() {
  return (
    <Container>
      <Instruction>
        <AnswerSidebar />
        <Content>
          <h4>벤자민 - AI 변호사</h4>
          <ProgressSection type="claim" />
        </Content>
      </Instruction>
      <ButtonArea>
        <PCSubmitButton
          type="button"
          inactive
        >
          작성 중...
        </PCSubmitButton>
      </ButtonArea>
    </Container>
  );
}
