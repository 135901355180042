import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import media from '../../styles/media';
import palette from '../../styles/palette';

import assetSource from '../../data/assetSource';

import useConsultation from '../../hooks/useConsultation';
import useAuth from '../../hooks/useAuth';
import useAdditionalConsultation from '../../hooks/useAdditionalConsultation';
import useHasDraftClaim from '../../hooks/useHasDraftClaim';
import useIsClaimSendable from '../../hooks/useIsClaimSendable';
import useResponsive from '../../hooks/useResponsive';

import InduceLoginSection from '../consultation/InduceLoginSection';
import MobileClaimGuideSection from '../consultation/MobileClaimGuideSection';
import QuestionInput from '../consultation/QuestionInput';
import QuestionSubmit from '../consultation/QuestionSubmit';
import PageLoading from '../common/PageLoading';
import ClaimGuideSection from './ClaimGuideSection';
import ActionContainer from './ActionContainer';
import typography from '../../styles/typography';
import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';

const Container = styled.div<{ noBorderTop: boolean; }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.5em;
  padding-top: 0.5em;
  border-top: ${({ noBorderTop }) => (noBorderTop ? 'none' : `1px solid ${palette.containerLightGrey}`)};

  ${media.isMobile`
    flex-direction: column-reverse;
    border-top: 1px solid ${palette.containerLightGrey};
    padding: 0.5em 0.75em;
  `}
`;

const ClaimGuideButton = styled.button<{ inactive: boolean }>`
  position: relative;
  min-width: 6em;
  padding-inline: 0.5em;
  border-radius: 25px;
  height: 48px;
  background-color: ${({ inactive }) => (inactive ? palette.containerLightGrey : consultationCategoryDictionary.legal.color.superlight)};
  cursor: ${({ inactive }) => (inactive ? 'default' : 'pointer')};
  pointer-events: ${({ inactive }) => (inactive ? 'none' : 'auto')};
  color: ${({ inactive }) => (inactive ? palette.textWhite : consultationCategoryDictionary.legal.color.dark)};

  ${typography.body1Button}

  ${media.isMobile`
    min-width: 4.5em;
    height: 44px;
    border-radius: 16px;
  `}

  img {
    width: 24px;
    aspect-ratio: 1;
  }
`;

export default function LegalQuestionForm() {
  const {
    consultation,
    isLoading,
    isAdvising,
    isAnswering,
  } = useConsultation();

  const {
    ask,
    updateQuestion,
    question,
  } = useAdditionalConsultation();

  const {
    loadHasDraftClaim,
    hasDraftClaim,
    draftClaimId,
    hasDraftClaimLoading,
  } = useHasDraftClaim();

  const {
    loadIsClaimSendable,
    isClaimSendable,
    isClaimSendableLoading,
  } = useIsClaimSendable();

  const { consultationId } = useParams();
  const { additionalConsultation } = consultation;
  const { isQuestionTrialEnd } = useAuth();
  const { authorizeConsultation, isConsultationAuthorized } = useAuth();
  const { isMobile } = useResponsive();

  const handleChangeQuestion = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateQuestion(event.target.value);
  };

  useEffect(() => {
    if (consultationId) {
      authorizeConsultation(consultationId);
    }
  }, [authorizeConsultation, consultationId]);

  useEffect(() => {
    if (consultationId) {
      loadHasDraftClaim(consultationId);
      loadIsClaimSendable(consultationId);
    }
  }, [loadHasDraftClaim, loadIsClaimSendable, consultationId]);

  const [isClaimGuideOpen, setIsClaimGuideOpen] = useState<boolean>(false);

  const handleClickQuestionSubmit = () => {
    setIsClaimGuideOpen(false);
    ask('legal', consultation.id);
  };
  const handleClickClaimGuideButton = () => {
    setIsClaimGuideOpen(!isClaimGuideOpen);
  };

  const claimGuideButtonText = (isAdvising || isAnswering) ? '답변 중' : '내용증명';
  if ((isLoading || hasDraftClaimLoading || isClaimSendableLoading) && !isAdvising) {
    return (
      <PageLoading />
    );
  }

  return (
    <Container noBorderTop={isQuestionTrialEnd(additionalConsultation)}>
      {isQuestionTrialEnd(additionalConsultation) && <InduceLoginSection />}
      <ActionContainer>
        {
          (isConsultationAuthorized && isClaimSendable)
            ? (
              <ClaimGuideButton
                id={isClaimGuideOpen ? '' : 'open-claim-guide-button'}
                onClick={handleClickClaimGuideButton}
                inactive={isAdvising || isAnswering}
              >
                {isClaimGuideOpen
                  ? <img src={`${assetSource}/assets/tilt-plus.svg`} alt="보내기 버튼 비활성화" />
                  : claimGuideButtonText}
                {(
                  isClaimGuideOpen
                  && (isMobile ? (
                    <MobileClaimGuideSection
                      hasDraftClaim={hasDraftClaim}
                      draftClaimId={draftClaimId}
                    />
                  ) : (
                    <ClaimGuideSection
                      hasDraftClaim={hasDraftClaim}
                      draftClaimId={draftClaimId}
                    />
                  ))
                )}
              </ClaimGuideButton>
            )
            : null
        }
        <QuestionInput>
          <TextareaAutosize
            maxRows={3}
            minRows={1}
            placeholder={isAnswering || isAdvising ? '답변을 생각중입니다...' : '질문을 입력하세요...'}
            disabled={isAnswering || isAdvising || isQuestionTrialEnd(additionalConsultation)}
            value={question}
            onChange={handleChangeQuestion}
          />
          <QuestionSubmit
            inactive={isAnswering || isAdvising || question === ''}
            consultationCategory="legal"
          >
            <button type="button" onClick={handleClickQuestionSubmit}>
              {question
                ? <img src={`${assetSource}/assets/send-button.svg`} alt="보내기 버튼" />
                : <img src={`${assetSource}/assets/send-button-inactive.svg`} alt="보내기 버튼 비활성화" />}
            </button>
          </QuestionSubmit>
        </QuestionInput>
      </ActionContainer>
    </Container>
  );
}
