import styled from 'styled-components';

import SyncLoader from 'react-spinners/SyncLoader';

import media from '../../styles/media';
import palette from '../../styles/palette';
import typography from '../../styles/typography';

import { ClaimStatus } from '../../types/ClaimInfo';

import getCurrentKoreanDate from '../../utils/getCurrentKoreanDate';

import LoadingIcon from '../common/LoadingIcon';

const Container = styled.div<{ isLoading: boolean }>`
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
  border-radius: 10px;
  border: 1px solid ${palette.containerLightGrey};
  white-space: pre-wrap;
  height: ${({ isLoading }) => (isLoading ? '37.375em' : 'auto')};
  overflow: hidden;
  animation: ${({ isLoading }) => (isLoading ? 'none' : 'expandContent 1s ease-out')};
  
  @keyframes expandContent {
    from {
      height: 37.375em;
    }
    to {
      height: 1100px;
    }
  }

  p {
    ${typography.body1}
    padding: 0.5em;
    white-space: pre-wrap;
  }

  ${media.isMobile`
    border: none;
    padding: 8px 12px;

    p {
      ${typography.body1}
    }
  `}
`;

const Title = styled.h2`
  ${typography.heading3}
  text-align: center;

  ${media.isMobile`
    ${typography.heading2Mobile}
  `}
`;

const TitleLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
`;

const ContentLoading = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media.isMobile`
    height: 40vh;
  `}
`;

export const DateAndName = styled.div`
  display: flex;
  flex-direction: column;
  padding-block: 2em;
  gap: 1em;
`;

export const CompletedAtDate = styled.h3`
  ${typography.heading3}
  text-align: center;

  ${media.isMobile`
    ${typography.heading2Mobile}
  `}
`;

export const LawyerName = styled.h4`
  ${typography.heading4}
  text-align: center;
  
  ${media.isMobile`
    ${typography.heading3Mobile}
  `}
`;

export default function ClaimContent({ content, title, status }: {
  content: string;
  title: string;
  status: ClaimStatus;
}) {
  const [foreword, claimContent] = content.split('\n\n\n');
  const claimContentParts = (claimContent || '').split('\n\n');
  const isLoadingStatus = status === 'init' || status === 'modifying';

  if (isLoadingStatus) {
    return (
      <Container isLoading={isLoadingStatus}>
        <Title>
          <TitleLoading>
            <LoadingIcon />
            제목을 작성하고 있습니다.
          </TitleLoading>
        </Title>
        <p>
          {`수신인: [미입력]
전화번호: [미입력]

발신인: AI 변호사 벤자민
전화번호: 070-7666-9909

발신의뢰인: [미입력]
전화번호: [미입력]`}
        </p>
        <ContentLoading>
          <SyncLoader color={palette.primary} size={20} />
        </ContentLoading>
      </Container>
    );
  }

  return (
    <Container isLoading={isLoadingStatus}>
      <Title>{title}</Title>
      <p>{foreword}</p>
      {claimContentParts.map((part) => (
        <p key={part}>{part}</p>
      ))}
      <DateAndName>
        <CompletedAtDate>{getCurrentKoreanDate()}</CompletedAtDate>
        <LawyerName>AI 변호사 벤자민</LawyerName>
      </DateAndName>
    </Container>
  );
}
