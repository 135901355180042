import styled from 'styled-components';

import { Outlet } from 'react-router-dom';

import Header from '../Header';
import Footer from '../Footer';

import media from '../../styles/media';

import useHeaderHeight from '../../hooks/useHeaderHeight';

const Container = styled.div<{ headerHeight: number; isHomepage: boolean; }>`
  padding-top: ${({ headerHeight }) => `${headerHeight}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ isHomepage }) => (isHomepage ? 'linear-gradient(138deg, #E45FB9 3.16%, #FF8080 11.26%, #FFAF80 17.73%, #FFDFBF 25.77%, #F7F4F0 35.1%, #F7F7F7 49.79%, #EDF7F7 62.42%, #C6FAFF 72.7%, #8DF5FF 80.79%, #87BBFF 91.22%, #5B8BF4 97.67%)' : 'none')};
  backdrop-filter: blur(2000px);
`;

const MainLayout = styled.div<{ isConsultationPage: boolean; isHomepage: boolean; }>`
  min-height: 80svh;
  width: 100%;
  max-width: 68em;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-block: ${({ isConsultationPage, isHomepage }) => ((isConsultationPage || isHomepage) ? '0' : '2em')} ;

  ${media.isMobile`
    padding: 0;
  `}
`;

export default function PageLayout({
  withFooter,
  isHomepage = false,
  isSendClaimPage = false,
  isConsultationPage = false,
}: {
  withFooter: boolean;
  isHomepage?: boolean;
  isSendClaimPage?: boolean;
  isConsultationPage?: boolean;
}) {
  const { headerHeight } = useHeaderHeight();

  return (
    <Container headerHeight={headerHeight} isHomepage={isHomepage}>
      <Header
        isHomepage={isHomepage}
        isSendClaimPage={isSendClaimPage}
      />
      <MainLayout isConsultationPage={isConsultationPage} isHomepage={isHomepage}>
        <Outlet />
      </MainLayout>
      {withFooter && <Footer />}
    </Container>
  );
}

PageLayout.defaultProps = {
  isHomepage: false,
  isSendClaimPage: false,
  isConsultationPage: false,
};
