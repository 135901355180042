import { useCallback, useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { ClaimReceiver, ClaimSender, SendInfoStep } from '../types/ClaimInfo';
import claimSendInfoState from '../stores/claimSendInfoState';
import validatePhoneNumber from '../utils/validatePhoneNumber';

const steps: SendInfoStep[] = ['receiverName', 'receiverPhoneNumber', 'senderName', 'senderPhoneNumber', 'bankInfoOption', 'senderBankInfo'];

export default function useClaimSendInfo() {
  const [sendInfo, setSendInfo] = useRecoilState(claimSendInfoState);
  const { receiver, sender } = sendInfo;
  const [sendInfoStep, setSendInfoStep] = useState<SendInfoStep>('receiverName');
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState<boolean>(true);
  const isPhoneNumberStep = ['receiverPhoneNumber', 'senderPhoneNumber'].includes(sendInfoStep);

  const initializeSendInfo = useResetRecoilState(claimSendInfoState);

  const validatePhoneNumberByStep = useCallback(() => {
    if (sendInfoStep === 'receiverPhoneNumber' && !receiver.hasPhoneNumber) {
      setIsValidPhoneNumber(true);
      return true;
    }

    const phoneNumber = sendInfoStep === 'receiverPhoneNumber' ? receiver.phoneNumber : sender.phoneNumber;
    const isValid = validatePhoneNumber(phoneNumber);
    setIsValidPhoneNumber(isValid);

    return isValid;
  }, [receiver.hasPhoneNumber, receiver.phoneNumber, sendInfoStep, sender.phoneNumber]);

  const updateReceiver = useCallback((setter: (prev : ClaimReceiver) => ClaimReceiver) => {
    setSendInfo((prev) => ({
      ...prev,
      receiver: setter(prev.receiver),
    }));
  }, [setSendInfo]);

  const updateSender = useCallback((setter: (prev : ClaimSender) => ClaimSender) => {
    setSendInfo((prev) => ({
      ...prev,
      sender: setter(prev.sender),
    }));
  }, [setSendInfo]);

  const updateReceiverName = useCallback((content: string) => {
    updateReceiver((prev) => ({
      ...prev,
      // 20글자 max 제한
      name: content.slice(0, 20),
    }));
  }, [updateReceiver]);

  const updateReceiverPhoneNumber = useCallback((content: string) => {
    // 유저가 액션을 취하면 초기화
    setIsValidPhoneNumber(true);

    updateReceiver((prev) => ({
      ...prev,
      // - 입력 불가 + 11글자 max 제한
      phoneNumber: content.slice(0, 11).replace(/-/g, ''),
    }));
  }, [updateReceiver]);

  const updateReceiverHasName = useCallback((hasName: boolean) => {
    if (!hasName) {
      updateReceiver((prev) => ({
        ...prev,
        hasName,
        name: '',
      }));
      return;
    }
    updateReceiver((prev) => ({
      ...prev,
      hasName,
    }));
  }, [updateReceiver]);

  const updateReceiverHasPhoneNumber = useCallback((hasPhoneNumber: boolean) => {
    // 유저가 액션을 취하면 초기화
    setIsValidPhoneNumber(true);

    if (!hasPhoneNumber) {
      updateReceiver((prev) => ({
        ...prev,
        hasPhoneNumber,
        phoneNumber: '',
      }));
      return;
    }
    updateReceiver((prev) => ({
      ...prev,
      hasPhoneNumber,
    }));
  }, [updateReceiver]);

  const updateSenderName = useCallback((content: string) => {
    updateSender((prev) => ({
      ...prev,
      // 20글자 max 제한
      name: content.slice(0, 20),
    }));
  }, [updateSender]);

  const updateSenderPhoneNumber = useCallback((content: string) => {
    // 유저가 액션을 취하면 초기화
    setIsValidPhoneNumber(true);

    updateSender((prev) => ({
      ...prev,
      // - 입력 불가 + 11글자 max 제한
      phoneNumber: content.slice(0, 11).replace(/-/g, ''),
    }));
  }, [updateSender]);

  const updateSenderBankInfo = useCallback((content: string, type: 'name' | 'account') => {
    if (type === 'name') {
      updateSender((prev) => ({
        ...prev,
        bankName: content,
      }));
      return;
    }

    updateSender((prev) => ({
      ...prev,
      bankAccount: content,
    }));
  }, [updateSender]);

  const backStep = useCallback(() => {
    // 유저가 액션을 취하면 초기화
    setIsValidPhoneNumber(true);

    if (sendInfoStep === 'senderBankInfo') {
      updateSenderBankInfo('', 'name');
      updateSenderBankInfo('', 'account');
    }

    const currentStepIndex = steps.indexOf(sendInfoStep);
    const isFirstStep = currentStepIndex === 0;
    if (!isFirstStep) {
      setSendInfoStep(steps[currentStepIndex - 1]);
    }
  }, [sendInfoStep, updateSenderBankInfo]);

  const nextStep = useCallback(() => {
    if (isPhoneNumberStep) {
      const isValid = validatePhoneNumberByStep();

      if (!isValid) {
        return;
      }
    }

    const currentStepIndex = steps.indexOf(sendInfoStep);
    const isLastStep = sendInfoStep === 'senderBankInfo';
    if (!isLastStep) {
      setSendInfoStep(steps[currentStepIndex + 1]);
    }
  }, [isPhoneNumberStep, sendInfoStep, validatePhoneNumberByStep]);

  return {
    sendInfo,
    updateReceiverName,
    updateReceiverPhoneNumber,
    updateReceiverHasName,
    updateReceiverHasPhoneNumber,
    updateSenderName,
    updateSenderPhoneNumber,
    updateSenderBankInfo,
    sendInfoStep,
    backStep,
    nextStep,
    isValidPhoneNumber,
    validatePhoneNumberByStep,
    initializeSendInfo,
  };
}
