import { SajuBirth } from '../types/SajuBirth';
import bokeAPI from './bokeAPI';

export default async function createSajuConsultation({
  sajuInquiry,
  sajuBirth,
  guestId,
}: {
  sajuInquiry: string,
  sajuBirth: SajuBirth,
  guestId: string
}) {
  const {
    year, month, day, hour, gender, calendar, birthTimeUnknown,
  } = sajuBirth;

  const birthRequest = {
    year: year.replace(/[^0-9.-]/g, ''),
    month: month.replace(/[^0-9.-]/g, ''),
    day: day.replace(/[^0-9.-]/g, ''),
    hour: birthTimeUnknown ? null : hour.slice(-5, -3),
    minute: birthTimeUnknown ? null : 20,
    gender: gender === '남자' ? 'M' : 'F',
    calendar,
  };

  const { data } = await bokeAPI.post('/saju-consultations', {
    inquiryContent: sajuInquiry,
    birth: birthRequest,
    guestId,
  });

  return data;
}
