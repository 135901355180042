import styled from 'styled-components';
import { ChangeEvent } from 'react';

import palette from '../../styles/palette';
import media from '../../styles/media';
import typography from '../../styles/typography';

import useResponsive from '../../hooks/useResponsive';
import useClaimModify from '../../hooks/useClaimModify';

import assetSource from '../../data/assetSource';

import { AnswerSidebar } from '../Sidebar';
import ModifyRequestInput from './ModifyRequestInput';
import PCSubmitButton from '../common/PCSubmitButton';
import ClaimActionButton from '../ClaimActionButton';
import MarkdownContent from '../common/MarkdownContent';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 25em;
  height: 37.375em;
  padding: 1.5em 1em;
  border-radius: 10px;
  border: 1px solid ${palette.containerLightGrey};
  gap: 1.5em;

  ${media.isMobile`
    width: 100%;
    gap: 1em;
    padding: 0.75em 0.75em 2em 0.75em;
    overflow: scroll;
    border-radius: 0;
    border: none;
  `}
`;

const Instruction = styled.div`
  display: flex;
  gap: 0.5em;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.25em;

  h4 {
    color: ${palette.textBlack};
    ${typography.heading4}

    ${media.isMobile`
      ${typography.heading4Mobile}
    `}
  }

  p {
    color: ${palette.textBlack};
    ${typography.body1}

    ${media.isMobile`
      ${typography.body1}
    `}
  }
`;

const ButtonArea = styled.div`
  align-self: flex-end;

  ${media.isMobile`
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 11;
  
    width: 100vw;
    display: flex;
    padding: 0.5em;
  
    border-top: 1px solid ${palette.containerLightGrey};
    background-color: ${palette.containerWhite};
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.30);
  `}
`;

export default function ClaimEditFormSection() {
  const {
    modifyClaim,
    guideMessage,
    requestMessage,
    updateRequestMessage,
    requestMaxLength,
    recommendedRequestLength,
  } = useClaimModify();

  const isEmpty = requestMessage.length === 0;

  const { isMobile } = useResponsive();
  const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    updateRequestMessage(event.target.value);
  };
  return (
    <Container>
      <Instruction>
        <AnswerSidebar />
        <Content>
          <h4>벤자민 - AI 변호사</h4>
          <MarkdownContent content={guideMessage} category="legal" />
        </Content>
      </Instruction>
      <ModifyRequestInput
        handleChange={handleChange}
        requestMaxLength={requestMaxLength}
        requestMessage={requestMessage}
        recommendedRequestLength={recommendedRequestLength}
      />
      <ButtonArea>
        {isMobile
          ? (
            <ClaimActionButton status={isEmpty ? 'isInputEmpty' : 'requestModify'} onClickRight={modifyClaim} />
          ) : (
            <PCSubmitButton
              id="modify-claim-button"
              type="button"
              onClick={modifyClaim}
              inactive={isEmpty}
            >
              <img src={`${assetSource}/assets/pen_white.svg`} alt="질문 제출하기" />
              {isEmpty ? '내용을 입력해주세요' : '요청하기'}
            </PCSubmitButton>
          )}
      </ButtonArea>
    </Container>
  );
}
