import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import palette from '../../styles/palette';
import typography from '../../styles/typography';

import ConsultationCategory from '../../types/ConsultationCategory';
import consultationCategoryDictionary from '../../data/consultationCategoryDictionary';
import media from '../../styles/media';
import useResponsive from '../../hooks/useResponsive';

const Container = styled.button<{ category: ConsultationCategory }>`
  display: flex;
  gap: 1em;
  justify-content: center;
  padding: 0.5em 1em;
  border-radius: 12px;
  border: 1px solid ${palette.containerLightGrey};
  background-color: ${palette.containerWhite};
  
  ${media.isMobile`
    padding: 1em;
    width: ${({ category }: { category: ConsultationCategory }) => (category === 'mumul' ? '100%' : 'auto')};
  `}

  ${media.isPc`
    &:hover {
      cursor: pointer;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.30);
    }    
  `}
  
  img {
    width: 32px;
    aspect-ratio: 1;
  }

  div {
    height: 32px;
    display: flex;
    align-items: center;
    
    ${typography.heading3}
    color: ${palette.textBlack};

    ${media.isMobile`
      ${typography.heading2Mobile}
    `}
  }
`;

export default function ExpertCard(
  { consultationCategory }: { consultationCategory: ConsultationCategory },
) {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const {
    expertName,
    expertRole,
    profileImage,
  } = consultationCategoryDictionary[consultationCategory];

  const handleClick = () => {
    navigate(`/consultations/${consultationCategory}`);
  };

  return (
    <Container id="expert-card" className={consultationCategory} type="button" onClick={handleClick} category={consultationCategory}>
      <img
        src={profileImage}
        alt={expertName}
      />
      <div>
        {(isMobile && consultationCategory !== 'mumul')
          ? expertRole
          : `${expertName} - ${expertRole}`}
      </div>
    </Container>
  );
}
