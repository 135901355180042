import { useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useClaimInfo from './useClaimInfo';
import useKakao from './useKakao';
import fetchClaimSendInfo from '../service/fetchClaimSendInfo';
import { ClaimShareInfo } from '../types/ClaimInfo';
import ClipBoardCopyToast from '../components/common/ClipBoardCopyToast';

export default function useClaimShare() {
  const { claimInfo } = useClaimInfo();
  const { shareClaim } = useKakao();

  const createShareMessage = useCallback((claimSendInfo: ClaimShareInfo) => `수신인 성명: ${claimSendInfo.receiverName}
수신인 번호: ${claimSendInfo.receiverPhoneNumber}

발신인 성명: ${claimSendInfo.senderName}
발신인 번호: ${claimSendInfo.senderPhoneNumber}

안녕하세요, ${claimSendInfo.receiverName}님,

${claimSendInfo.senderName}님이 전송한 전자문서가 도착했습니다.

아래 링크를 클릭하여 문서를 확인하실 수 있습니다.

https://www.noke.ai/claims/${claimInfo.id}`, [claimInfo.id]);

  const kakaoShare = useCallback(async () => {
    const claimSendInfo = await fetchClaimSendInfo(claimInfo.id);
    const kakaoShareInfo = { claimId: claimInfo.id, ...claimSendInfo };
    shareClaim(kakaoShareInfo);
  }, [claimInfo.id, shareClaim]);

  const selfShare = useCallback(async () => {
    const claimSendInfo = await fetchClaimSendInfo(claimInfo.id);
    const selfShareMessage = createShareMessage(claimSendInfo);
    await navigator.clipboard.writeText(selfShareMessage);
    toast(<ClipBoardCopyToast />);
  }, [claimInfo.id, createShareMessage]);

  const mobileWebShare = useCallback(async () => {
    const claimSendInfo = await fetchClaimSendInfo(claimInfo.id);
    const selfShareMessage = createShareMessage(claimSendInfo);

    if (navigator.share) {
      navigator.share({
        title: '전자문서가 도착했습니다.',
        text: selfShareMessage,
        url: `claims/${claimInfo.id}`,
      });
    } else {
      await navigator.clipboard.writeText(selfShareMessage);
      toast(<ClipBoardCopyToast />);
    }
  }, [claimInfo.id, createShareMessage]);

  return {
    kakaoShare,
    selfShare,
    mobileWebShare,
  };
}
