import bokeAPI from './bokeAPI';

export default async function requestModifyClaim({
  claimId,
  requestMessage,
}: {
  claimId: string;
  requestMessage: string;
}) {
  const { data } = await bokeAPI.post(`/claims/${claimId}/modify`, {
    requestMessage,
  });

  return data;
}
