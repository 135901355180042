import styled from 'styled-components';

import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import palette from '../styles/palette';
import typography from '../styles/typography';
import media from '../styles/media';

import MobileHeaderRightButton from './MobileHeaderRightButton';
import PCHeaderRightButton from './PCHeaderRightButton';

import assetSource from '../data/assetSource';
import useClaimInfo from '../hooks/useClaimInfo';
import useResponsive from '../hooks/useResponsive';
import SubHeader from './SubHeader';
import useHeaderHeight from '../hooks/useHeaderHeight';

const Container = styled.div<{ isHomepage: boolean; }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 6;
  border-bottom: ${({ isHomepage }) => (isHomepage ? 'none' : `1px solid ${palette.containerLightGrey}`)} ;
  background-color: ${({ isHomepage }) => (isHomepage ? 'transparent' : palette.containerWhite)};
`;

const MainHeader = styled.div`
  position: relative;
  padding: 0.5em 2em;
  display: flex;
  justify-content: end;
  align-items: center;

  ${media.isMobile`
    padding: 0.5em 1em;
    justify-content: space-between;
  `}
`;

const Title = styled.a<{ positionCenter: boolean; }>`
${({ positionCenter }) => (
    positionCenter
      ? `
position: absolute;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);`
      : null)
}

  display: flex;
  align-items: center;
  gap: 1em;
  cursor: pointer;
  
  img {
    width: 35px;
    height: auto;
  }

  h1 {
    color: ${palette.primary};
    ${typography.heading4}
  }

  ${media.isMobile`
    gap: 0.5em;

    h1 {
      ${typography.heading4Mobile}
    }
  `}
`;

const SendClaimStatus = styled.div`
  padding: 4px 8px;
  ${typography.body3Bold}
  color: ${palette.primary};
`;

export default function Header({ isHomepage, isSendClaimPage }: {
  isHomepage: boolean;
  isSendClaimPage: boolean;
}) {
  const { headerRef, updateHeaderHeight } = useHeaderHeight();
  const { claimInfo } = useClaimInfo();
  const { status } = claimInfo;
  const { isMobile } = useResponsive();

  const location = useLocation();

  useLayoutEffect(() => {
    if (headerRef.current) {
      updateHeaderHeight();
      window.addEventListener('load', updateHeaderHeight);
      window.addEventListener('resize', updateHeaderHeight);
    }

    return () => {
      window.removeEventListener('load', updateHeaderHeight);
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, [headerRef, location, updateHeaderHeight]);

  if (isSendClaimPage && isMobile) {
    const sendClaimPageTitle = {
      init: '로딩 중',
      modifying: '수정 중',
      edit: '1단계: 수정하기',
      send: '2단계: 정보입력',
      done: '3단계: 전달하기',
      sending: '전송 중',
    }[status];

    return (
      <Container ref={headerRef} isHomepage={isHomepage}>
        <MainHeader>
          <SendClaimStatus>{sendClaimPageTitle}</SendClaimStatus>
          <Title href="/" positionCenter id="homepage-button">
            <img alt="노크 로고" src={`${assetSource}/assets/logo-default-v2.png`} />
            <h1>노크</h1>
          </Title>
        </MainHeader>
      </Container>
    );
  }

  return (
    <Container ref={headerRef} isHomepage={isHomepage}>
      <MainHeader>
        <Title href="/" positionCenter={!isMobile} id="homepage-button">
          <img alt="노크 로고" src={`${assetSource}/assets/logo-default-v2.png`} />
          <h1>노크</h1>
        </Title>
        {isMobile
          ? <MobileHeaderRightButton />
          : <PCHeaderRightButton />}
      </MainHeader>
      {
        (isHomepage || isSendClaimPage) || (
          <SubHeader />
        )
      }
    </Container>
  );
}
